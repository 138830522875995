import { call, put, takeEvery, select } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import {
  updateCustomRiskFactorSuccess,
  updateCustomRiskFactorFailure,
  calculateSummaryRequest,
  loadCustomRiskFactorRequest,
  updateCustomRiskFactorRequest
} from '../actions'
import { toSnakeCase } from '../../../../services/api/util'
import { CustomRiskFactor } from '../../types'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchUpdateCustomRiskFactor() {
  yield takeEvery(ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_REQUEST, updateCustomRiskFactor)
}

function* updateCustomRiskFactor(action: BaseAction) {
  const { proposalId, customRiskFactor, currency } = action.payload
  try {
    const data = toSnakeCase(customRiskFactor)
    const result: CustomRiskFactor = yield call(api.updateCustomRiskFactor, proposalId, data)
    yield put(updateCustomRiskFactorSuccess(result))

    // refresh custom-risk-factor
    yield put(loadCustomRiskFactorRequest(proposalId))

    // Refresh summaryList
    const state = yield select()
    const proposal = state.proposal.proposalDetail.proposal
    yield put(calculateSummaryRequest(proposal.id, proposal.hedge!.pricingDate, currency))

  } catch (error) {
    console.log(error)
    yield put(updateCustomRiskFactorFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        updateCustomRiskFactorRequest(proposalId, customRiskFactor, currency)
      )
    }
  }
}
