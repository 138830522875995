import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { Proposal } from "../.."
import {
  updateProposalSuccess,
  updateProposalRequest,
  updateProposalFailed
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


export function* watchUpdateProposal() {
  yield takeEvery(ActionTypes.UPDATE_PROPOSAL_REQUEST, updateProposal)
}

function* updateProposal(action: BaseAction) {
  const proposal = action.payload as Proposal
  try {
    const newProposal = yield call(api.updateProposal,
      proposal.id,
      proposal.activeCard ? proposal.activeCard.id : undefined,
      proposal.loan ? proposal.loan.id : undefined,
      proposal.hedge ? proposal.hedge.id : undefined)
    yield put(updateProposalSuccess(newProposal))
  } catch (error) {
    console.error(error)
    yield put(updateProposalFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateProposalRequest(proposal))
    }
  }
}
