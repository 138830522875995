import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import { ScenarioSummary } from "../../types"
import {
  calculateSummarySuccess,
  calculateSummaryRequest,
  calculateSummaryFailure
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchCalculateSummary() {
  yield takeEvery(ActionTypes.CALCULATE_SUMMARY_REQUEST, calculateSummary)
}

function* calculateSummary(action: BaseAction) {
  const proposalId: number = action.payload.proposalId
  const currency: string = action.payload.currency
  const asOf: string = action.payload.asOf

  try {
    const result = yield call(api.calculateSummary, proposalId, asOf, currency)
    let summaryList: ScenarioSummary[] = result

    //Server calculates numbers from bank-view. We want to display all numbers from client-view. Therefore, translate them here.
    if (summaryList)
      summaryList = summaryList.map(summary => {
        return {
          ...summary,
          hedge: summary.hedge * -1,
          loan: summary.loan * -1,
          total: summary.total * -1,
        }
      })

    yield put(calculateSummarySuccess(summaryList))
  } catch (error) {
    console.error(error)
    yield put(calculateSummaryFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(calculateSummaryRequest(proposalId, asOf, currency))
    }
  }
}
