export enum LoginStatus {
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGING_IN = 'LOGGING_IN',
  LOGGED_IN = 'LOGGED_IN',
  LOGGING_OUT = 'LOGGING_OUT',
  LOGIN_FAILED = 'LOGIN_FAILED',
  EXPIRED = 'EXPIRED',
}

export enum ChangePwdStatus {
  CHANGE_PWD_SUCCESS = 'CHANGE_PWD_SUCCESS',
  CHANGE_PWD_PROCESSING = 'CHANGE_PWD_PROCESSING',
  CHANGE_PWD_FAILED = 'CHANGE_PWD_FAILED',
}

export interface UserProfile {
  id: number,
  email: string,
  isStructurer: boolean,
  locationCode: string,
  defaultCurrencies: string[],
}
