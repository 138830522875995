import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import ActionTypes from './types'
import { Client } from '../types';

type ClientAction = ActionType<typeof actions>

const initialState = {
  client: undefined as Client | undefined,
  error: undefined as string | undefined,
  busyCount: 0,
}

export type ClientEditState = typeof initialState

const reducer: Reducer<ClientEditState, ClientAction> =
  (state = initialState, action: ClientAction) => {
    switch (action.type) {

      case ActionTypes.GET_CLIENT_REQUEST:
        return {
          ...state,
          client: undefined
        }

      case ActionTypes.GET_CLIENT_SUCCESS:
        return {
          ...state,
          client: action.payload.client,
        }

      case ActionTypes.GET_CLIENT_FAILURE:
        return {
          ...state,
          client: undefined,
          busyCount: calcBusyCount(state.busyCount, -1)
        }

      case ActionTypes.CREATE_CLIENT_REQUEST:
        return {
          ...state,
          error: undefined,
          busyCount: calcBusyCount(state.busyCount, +1)
        }

      case ActionTypes.CREATE_CLIENT_SUCCESS:
        return {
          ...state,
          client: undefined,
          error: undefined,
          busyCount: calcBusyCount(state.busyCount, -1)
        }

      case ActionTypes.CREATE_CLIENT_FAILURE:
        return {
          ...state,
          error: action.payload.error,
          busyCount: calcBusyCount(state.busyCount, -1)
        }

      case ActionTypes.UPDATE_CLIENT_REQUEST:
        return {
          ...state,
          error: undefined,
          busyCount: calcBusyCount(state.busyCount, +1)
        }

      case ActionTypes.UPDATE_CLIENT_SUCCESS:
        return {
          ...state,
          client: action.payload.client,
          error: undefined,
          busyCount: calcBusyCount(state.busyCount, -1)
        }

      case ActionTypes.UPDATE_CLIENT_FAILURE:
        return {
          ...state,
          error: action.payload.error,
          busyCount: calcBusyCount(state.busyCount, -1)
        }

      case ActionTypes.RESET_ERROR:
        return {
          ...state,
          error: undefined
        }

      default:
        return state
    }
  }

function calcBusyCount(currentBusyCount: number, change: 1 | -1): number {
  if (change === -1) {
    return (currentBusyCount > 0) ? currentBusyCount + change : 0
  } else {
    return currentBusyCount + change
  }
}

export default reducer
