import { action } from 'typesafe-actions'

import { ActionTypes } from '../action-types'

export function changePwdRequest(oldPwd: string, newPwd: string) {
  return action(ActionTypes.CHANGE_PWD_REQUEST, { oldPwd, newPwd })
}

export function changePwdSuccess() {
  return action(ActionTypes.CHANGE_PWD_SUCCESS)
}

export function changePwdProcessing() {
  return action(ActionTypes.CHANGE_PWD_PROCESSING)
}

export function changePwdFail(errorMsg: string[]) {
  return action(ActionTypes.CHANGE_PWD_FAILED, { errorMsg })
}
