import * as React from 'react'
import { Card, Container, Form } from 'react-bootstrap'

import { LoginStatus } from '../../../../store/auth/types'
import logo from '../../../../assets/logo.png'

interface InputProps {
  loginError: string
  loginTouch: LoginTouch
  formikProps: any
  loginStatus: LoginStatus
  onChange: (loginData: LoginType) => void
  onTouch: (loginTouch: LoginTouch) => void
  onClearError: () => void
}

type Props = InputProps

export type LoginType = {
  email: string
  password: string
}

export type LoginTouch = {
  email: boolean
  password: boolean
}

function LoginPageFormInput(props: Props) {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = props.formikProps

  const { loginError, loginTouch, loginStatus } = props
  const loginData = values

  if (!loginData)
    return null


  function handleEmailChange(e: any) {
    handleChange(e)
    setFieldTouched('email', true)
    props.onChange({ ...loginData, email: e.target.value })
    props.onTouch({ ...loginTouch, email: true })
    props.onClearError()
  }

  function handlePasswordChange(e: any) {
    handleChange(e)
    setFieldTouched('password', true)
    props.onChange({ ...loginData, password: e.target.value })
    props.onTouch({ ...loginTouch, password: true })
    props.onClearError()
  }

  return (
    <>
      <Container fluid className={'d-flex justify-content-center align-items-center vh-80'}>
        <Form
          onSubmit={handleSubmit}
          className={'mt-5'}>
          <Card
            className={'rounded-10 shadow'}
            style={{ width: '24rem', }}>

            <Card.Body className={'px-5 pt-5'}>
              <div className='text-center'>
                <img src={logo} alt='Quick Scan' width='64px' />
              </div>
              <h5 className={'text-center text-tertiary mt-4'}>Sign In</h5>
              <hr className={'w-100 mb-5'} />
              <Form.Group controlId="fg-email">
                <Form.Control
                  type="text"
                  placeholder="Email *"
                  defaultValue={loginData.email}
                  onChange={handleEmailChange}
                  onKeyUp={handleEmailChange}
                  onBlur={handleEmailChange}
                  isInvalid={loginTouch.email && errors.email}
                  className={'mt-4'} />
                {
                  (loginTouch.email && errors.email) &&
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                }
              </Form.Group>
              <Form.Group controlId="fg-password">
                <Form.Control
                  type="password"
                  placeholder="Password *"
                  value={loginData.password}
                  onChange={handlePasswordChange}
                  onKeyUp={handlePasswordChange}
                  onBlur={handlePasswordChange}
                  isInvalid={loginTouch.password && errors.password}
                  className={'mt-4'} />
                {
                  (loginTouch.password && errors.password) &&
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                }
              </Form.Group>
              <Form.Group
                controlId="fg4"
                className={'text-center d-flex align-items-center flex-column'}>
                {
                  ((loginStatus === LoginStatus.LOGIN_FAILED) && loginError) &&
                  <small className={'text-danger text-center'}>
                    {loginError}
                  </small>
                }
              </Form.Group>

            </Card.Body>
            <Card.Footer className={'text-center bg-white px-5 pb-5 border-top-0'}>
              <button
                type={'submit'}
                className={'btn btn-outline-primary btn-sm mx-2'}>
                LOGIN
              </button>
            </Card.Footer>
          </Card>
        </Form>
      </Container>
    </>
  )
}

export default LoginPageFormInput