import { watchChangePwd } from './change-password'
import { watchLogin } from './login'
import { watchLogout } from './logout'
import { watchRefreshToken } from './refresh-token'
import { watchLoadUserProfile } from './user-profile'

export default [
  watchChangePwd,
  watchLogin,
  watchLogout,
  watchRefreshToken,
  watchLoadUserProfile,
]
