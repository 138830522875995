import { call, put, takeEvery } from "@redux-saga/core/effects"

import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import * as proposalSummaryApi from '../../../../services/api/proposal-summary'
import { DiscussionSummary } from "../../types"
import {
  refreshProposalRequest,
  requestDiscussionSummaryReportSuccess,
  requestDiscussionSummaryReportFailed,
  requestDiscussionSummaryReportRequest
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchRequestDiscussionSummaryReport() {
  yield takeEvery(ActionTypes.REQUEST_DISCUSSION_SUMMARY_REPORT_REQUEST, requestDiscussionSummaryReport)
}

function* requestDiscussionSummaryReport(action: BaseAction) {
  const summary: DiscussionSummary = action.payload.summary
  const id: number = action.payload.id
  try {
    yield call(
      proposalSummaryApi.updateDiscussionSummary,
      summary,
      summary.id,
    )
    yield put(requestDiscussionSummaryReportSuccess())
    yield put(refreshProposalRequest(id))
  } catch (error) {
    yield put(requestDiscussionSummaryReportFailed())
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(requestDiscussionSummaryReportRequest(summary, id))
    }
  }
}
