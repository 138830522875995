import { call, put, takeEvery } from "@redux-saga/core/effects"

import { ActionTypes } from '../action-types'
import { statusChangingPwd, statusChangedPwd } from "../actions/status"
import * as api from '../../../services/api/auth'
import { BaseAction } from "../../../services/action"
import { changePwdSuccess, changePwdFail, changePwdRequest } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../util"
import { isAzureAD } from "../../../services/api/azure-ad"

export function* watchChangePwd() {
  yield takeEvery(ActionTypes.CHANGE_PWD_REQUEST, changePassword)
}

function* changePassword(action: BaseAction) {
  yield put(statusChangingPwd())
  const { oldPwd, newPwd } = action.payload
  try {

    yield call(api.changePwd, oldPwd, newPwd)
    yield put(changePwdSuccess())
    yield put(statusChangedPwd())

  } catch (error) {
    let responseCode = error.response.status
    let messages: string[] = ['Change password is error.']

    if (responseCode === 400) {
      messages = error.response.data.new_password || error.response.data.current_password
      if (error.response.data && error.response.data.hasOwnProperty('current_password')) {
        let responseDetail = error.response.data.current_password[0]
        responseDetail = (responseDetail === 'Invalid password.') ? 'Invalid current password.' : responseDetail
        messages = [responseDetail]
      }
    } else if (responseCode === 401) {
      messages = [error.response.data.detail]
    } else if (responseCode === 503) {
      messages = ['Cannot connect to internet.']
    } else if (responseCode === 401) {
      messages = ['Unauthorized access.']
    }
    yield put(changePwdFail(messages))
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(changePwdRequest(oldPwd, newPwd))
    }
  }
}
