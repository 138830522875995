import { ActionType } from 'typesafe-actions'

import { ActionTypes } from './types'
import * as actions from './actions'
import { deepClone } from '../../../services/util'
import { Loan } from '../types'

type LoanAction = ActionType<typeof actions>

const initialState = {
  loan: undefined as any as Loan,
  isLoading: false,
  error: undefined as string | undefined
}

export type LoanDetailState = typeof initialState

function reducer(state: LoanDetailState = initialState, action: LoanAction) {
  switch (action.type) {

    case ActionTypes.UPDATE_LOAN:
      return Object.assign({}, state, {
        loan: deepClone(action.payload)
      })

    case ActionTypes.IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload
      })
    case ActionTypes.UPDATE_LOAN_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case ActionTypes.RESET_LOAN_ERROR_MESSAGE:
      return {
        ...state,
        error: undefined
      }
    default:
      return state
  }
}

export default reducer
