import { call, put, takeEvery } from "@redux-saga/core/effects"

import { ActionTypes } from '../action-types'
import * as api from '../../../services/api/auth'
import { BaseAction } from "../../../services/action"
import jwtDecode from 'jwt-decode'
import * as jwt from "../../../services/auth"
import { loginSuccess, loginFail, statusLoggingIn, statusLoggedIn }
  from "../actions"
import { loadUserProfileRequest } from "../actions/user-profile"

export function* watchLogin() {
  yield takeEvery(ActionTypes.LOGIN_REQUEST, login)
}

function* login(action: BaseAction) {
  yield put(statusLoggingIn())
  const { email, password } = action.payload

  try {
    const token = yield call(api.requestToken, email, password)
    const payload = jwtDecode(token)

    jwt.saveToken(token)
    yield put(loginSuccess(token, payload))
    yield put(statusLoggedIn())

    // Load the user profile
    yield put(loadUserProfileRequest())
  }
  catch (error) {
    const responseCode = error.response.status
    let msg = 'Login is error.'
    if (responseCode === 401) {
      msg = 'Email or password is incorrect.'
    } else if (responseCode === 503) {
      msg = 'Cannot connect to internet.'
    }
    yield put(loginFail(email, msg))
  }
}
