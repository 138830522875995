const width = 190
const height = 170
export const faCustomChart: any = {
  prefix: "fas",
  iconName: "custom-chart",
  icon: [
    width,
    height,
    [],
    "f0000",
    "M180.21,107.18,133.15,60.12a9.83,9.83,0,0,1,0-13.9h0a9.83,9.83,0,0,1,13.9,0l47.06,47.06a9.83,9.83,0,0,1,0,13.9h0A9.83,9.83,0,0,1,180.21,107.18Z M194.11,107.18l-47.06,47.06a9.83,9.83,0,0,1-13.9,0h0a9.83,9.83,0,0,1,0-13.9l47.06-47.06a9.84,9.84,0,0,1,13.9,0h0A9.83,9.83,0,0,1,194.11,107.18Z M24.08,109.17a9,9,0,0,1-8.27-12.55L50.88,19.17a9,9,0,0,1,16.64.24L97.6,91.17H188a9,9,0,0,1,0,18H91.49a9,9,0,0,1-8.37-5.69L58.81,46.32l-26.46,57.4A9,9,0,0,1,24.08,109.17Z"
  ]
}
export const faLockOutline: any = {
  prefix: "fas",
  iconName: "lock-outline",
  icon: [
    381,
    436,
    [],
    "f0000",
    "M68,164v-5.72c0-8-.11-16,0-24,.17-8.3.18-16.65,1-24.89A115.84,115.84,0,0,1,79.52,71.69c4.56-9.68,9.8-19,16.76-27.07,6-6.94,12.26-13.6,19.69-19.06a133.79,133.79,0,0,1,22.94-14,129.77,129.77,0,0,1,30.81-9.64A116.51,116.51,0,0,1,196.51.13a107.69,107.69,0,0,1,33.58,6.45,152.11,152.11,0,0,1,15.66,6.48,122.23,122.23,0,0,1,38.08,30.08,129,129,0,0,1,19.74,31.63,115.57,115.57,0,0,1,8.25,28.64c2.67,17.2,1.74,34.51,1.91,51.79,0,2.64,0,5.29,0,8.39,1.79.17,3.22.41,4.64.42,7.33,0,14.67.06,22,0,11.17-.08,21.08,3.6,29,11.28,7.58,7.36,12.38,16.42,12.38,27.42q0,79.23,0,158.46c0,11.66-.2,23.33.05,35,.24,11.39-4.13,20.94-11.88,28.68a39.49,39.49,0,0,1-28.7,11.93q-150,0-299.93,0c-11.38,0-21.37-3.9-29.28-11.76C4.41,417.47,0,408,0,396.9q0-97,0-194c0-8.8,3.28-16.49,8.74-23.35,7-8.76,16.08-13.65,27-15.34a29.18,29.18,0,0,1,4.49-.2q11,0,22,0Zm273.72,39.86H84.42c-13,0-26,0-39,0-1.76,0-3.69-.59-5.62,1.19V380.17c0,4.16-.05,8.33,0,12.49.07,3.56.62,4.08,4,4.26,1.16.06,2.33,0,3.49,0H296.76q19.74,0,39.49,0c1.76,0,3.52-.17,5.48-.28Zm-67.86-40.07c0-2.2,0-3.82,0-5.45-.29-13.3.22-26.6-.66-39.92a87.43,87.43,0,0,0-7.72-31.23c-5.84-12.64-14.83-22.84-25.93-31.15-14-10.5-30-15.47-47.39-16.2-6.19-.26-12.25.86-18.27,2a77.84,77.84,0,0,0-35.95,17.5c-10.26,8.74-18.41,19.19-23.33,31.91-3,7.63-5.57,15.43-5.75,23.62-.33,15.16-.14,30.33-.11,45.5a19.23,19.23,0,0,0,.6,3.39Z"
  ]
}
