import * as React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { initIcons } from '../icons/icons'
import AzureAD, { AuthenticationState } from 'react-aad-msal'
import { authProvider } from '../../services/api/azure-ad'
import AppWrapper from './app-wrapper'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { loadUserProfileRequest } from '../../store/auth/actions'

interface Props {
  loadUserProfile: () => void
}
function AppAzure(props: Props) {
  initIcons()
  return (
    <>
      {
        <AzureAD provider={authProvider} forceLogin={true}>
          {
            ({ login, logout, authenticationState, error, accountInfo }: any) => {
              if (authenticationState === AuthenticationState.InProgress) {
                return (
                  <div className={'spinner-box'} style={{ flexDirection: 'column' }}>
                    <div style={{ fontWeight: 600, paddingBottom: 20 }}>
                      Rediecting to Azure login portal...
                      </div>
                    <Spinner
                      animation="border"
                      variant={'primary'} />
                  </div>
                );
              }
              else if (authenticationState === AuthenticationState.Authenticated) {
                const isStorageSet = localStorage.getItem('msal.idtoken') ? true : false
                return (
                  <>
                    <CssBaseline />
                    <AppWrapper
                      onLoadUserProfile={props.loadUserProfile}
                      isStorageSet={isStorageSet} />
                  </>
                );
              }
              else if (authenticationState === AuthenticationState.Unauthenticated) {
                return (
                  <div>
                    {
                      error
                        ?
                        <p className={'spinner-box text-danger'}>
                          <span>
                            An error occured during authentication
                          </span>
                        </p>
                        :
                        <div className={'spinner-box'} style={{ flexDirection: 'column' }}>
                          <div style={{ fontWeight: 600, paddingBottom: 20 }}>
                            Loading...
                            </div>
                          <Spinner
                            animation="border"
                            variant={'primary'} />
                        </div>
                    }
                  </div>
                )
              }
            }
          }
        </AzureAD>
      }
    </>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => {
  return {}
}

const mapDispatchToProps = {
  loadUserProfile: loadUserProfileRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAzure)
