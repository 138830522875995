import { action } from "typesafe-actions"

import { Proposal, PriceDetail } from ".."
import ActionTypes from "./types"
import { HedgeCard } from "../../../services/hedge-card"
import { Loan } from "../../loan/types"
import { Hedge } from "../../hedge/types"
import { TermsheetStructure } from '../../../pages/proposal/proposal-summary/components/termsheet/utils'
import { DiscussionSummaryInput, ReferenceRate } from './../../proposal/types'

/*
** Proposal
*/
export function loadProposalRequest(proposalId: number) {
  return action(ActionTypes.LOAD_PROPOSAL_REQUEST, proposalId)
}

export function loadProposalSuccess(proposal: Proposal) {
  return action(ActionTypes.LOAD_PROPOSAL_SUCCESS, proposal)
}

export function loadProposalFailure() {
  return action(ActionTypes.LOAD_PROPOSAL_FAILURE)
}

/** Recalculate the price of the given proposal */
export function recalcProposalPriceRequest(proposal: Proposal, callback: any) {
  return action(ActionTypes.RECALC_PROPOSAL_PRICE_REQUEST, { proposal, callback })
}

export function recalcProposalPriceSuccess(proposal: Proposal) {
  return action(ActionTypes.RECALC_PROPOSAL_PRICE_SUCCESS, { proposal })
}

export function recalcProposalPriceFailure() {
  return action(ActionTypes.RECALC_PROPOSAL_PRICE_FAILURE)
}

export function updateProposalRequest(proposal: Proposal) {
  return action(ActionTypes.UPDATE_PROPOSAL_REQUEST, proposal)
}

export function updateProposalSuccess(proposal: Proposal) {
  return action(ActionTypes.UPDATE_PROPOSAL_SUCCESS, proposal)
}

export function updateProposalFailed() {
  return action(ActionTypes.UPDATE_PROPOSAL_FAILURE)
}

export function deleteProposalRequest(proposalId: number) {
  return action(ActionTypes.DELETE_PROPOSAL_REQUEST, proposalId)
}

export function deleteProposalSuccess() {
  return action(ActionTypes.DELETE_PROPOSAL_SUCCESS)
}

export function deleteProposalFailed() {
  return action(ActionTypes.DELETE_PROPOSAL_FAILURE)
}

export function refreshProposalRequest(proposalId: number) {
  return action(ActionTypes.REFRESH_PROPOSAL_REQUEST, proposalId)
}

export function refreshProposalSuccess(proposal: Proposal) {
  return action(ActionTypes.REFRESH_PROPOSAL_SUCCESS, proposal)
}

export function refreshProposalFailed() {
  return action(ActionTypes.REFRESH_PROPOSAL_FAILURE)
}

/*
** Active Card
*/
export function saveActiveCardRequest(proposal: Proposal, card: HedgeCard) {
  return action(ActionTypes.SAVE_ACTIVE_CARD_REQUEST, { proposal, card })
}

export function saveActiveCardSuccess() {
  return action(ActionTypes.SAVE_ACTIVE_CARD_SUCCESS)
}

export function saveActiveCardFailed() {
  return action(ActionTypes.SAVE_ACTIVE_CARD_FAILURE)
}

/*
** Deprecated?
*/
export function clearLoan() {
  return action(ActionTypes.CLEAR_LOAN)
}

export function clearHedge() {
  return action(ActionTypes.CLEAR_HEDGE)
}

/*
** Loan
*/
export function updateLoanRequest(proposal: Proposal, loan: Loan, card?: HedgeCard) {
  return action(ActionTypes.UPDATE_LOAN_REQUEST, { proposal, loan, card })
}

export function updateLoanSuccess(proposal: Proposal) {
  return action(ActionTypes.UPDATE_LOAN_SUCCESS, { proposal })
}

export function updateLoanFaield() {
  return action(ActionTypes.UPDATE_LOAN_FAILURE)
}

export function updatePreConfiguredLoanRequest(proposal: Proposal, loan: Loan) {
  return action(ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_REQUEST, {
    proposal, loan
  })
}

export function updatePreConfiguredLoanSuccess(proposal: Proposal) {
  return action(ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_SUCCESS, { proposal })
}

export function updatePreConfiguredLoanFailed() {
  return action(ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_FAILURE)
}

export function updateHedgeRequest(proposal: Proposal, hedge: Hedge, callback: any) {
  return action(ActionTypes.UPDATE_HEDGE_REQUEST, { proposal, hedge, callback })
}

export function updateHedgeSuccess(proposal: Proposal) {
  return action(ActionTypes.UPDATE_HEDGE_SUCCESS, { proposal })
}

export function updateHedgeFailed() {
  return action(ActionTypes.UPDATE_HEDGE_FAILURE)
}

export function calculatePriceDetailRequest(
  proposalId: number,
  pricingDate: string | undefined,
  margin: number | undefined,
  finalPrice: number | undefined) {
  return action(ActionTypes.CALCULATE_PRICE_DETAIL_REQUEST, {
    id: proposalId,
    pricingDate,
    margin,
    finalPrice
  })
}

export function calculatePriceDetailSuccess(priceDetail: PriceDetail) {
  return action(ActionTypes.CALCULATE_PRICE_DETAIL_SUCCESS, { priceDetail })
}

export function calculatePriceDetailFailure() {
  return action(ActionTypes.CALCULATE_PRICE_DETAIL_FAILURE)
}

export function updateProposalPriceRequest(
  proposalId: number,
  pricingDate: string | undefined,
  tradeDate: string | undefined,
  margin: number | undefined,
  finalPrice: number | undefined) {
  return action(ActionTypes.UPDATE_PROPOSAL_PRICE_REQUEST, {
    id: proposalId,
    pricingDate,
    tradeDate,
    margin,
    finalPrice
  })
}

export function updateProposalPriceSuccess(proposal: Proposal) {
  return action(ActionTypes.UPDATE_PROPOSAL_PRICE_SUCCESS, { proposal })
}

export function updateProposalPriceFailure() {
  return action(ActionTypes.UPDATE_PROPOSAL_PRICE_FAILURE)
}

// Discussion Summary
export function updateDiscussionSummaryRequest(
  summary: DiscussionSummaryInput,
  id: number,
) {
  return action(
    ActionTypes.UPDATE_DISCUSSION_SUMMARY_REQUEST,
    { summary, id },
  )
}

export function updateDiscussionSummarySuccess() {
  return action(ActionTypes.UPDATE_DISCUSSION_SUMMARY_SUCCESS)
}

export function updateDiscussionSummaryFailed() {
  return action(ActionTypes.UPDATE_DISCUSSION_SUMMARY_FAILURE)
}

export function requestDiscussionSummaryReportRequest(
  summary: DiscussionSummaryInput,
  id: number,
) {
  return action(
    ActionTypes.REQUEST_DISCUSSION_SUMMARY_REPORT_REQUEST,
    { summary, id },
  )
}

export function requestDiscussionSummaryReportSuccess() {
  return action(ActionTypes.REQUEST_DISCUSSION_SUMMARY_REPORT_SUCCESS)
}

export function requestDiscussionSummaryReportFailed() {
  return action(ActionTypes.REQUEST_DISCUSSION_SUMMARY_REPORT_FAILURE)
}

export function generateProposalSummaryPDFRequest(
  proposal: Proposal,
  callback: (item: any) => void
) {
  return action(
    ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_REQUEST,
    { proposal },
    { callback }
  )
}

export function generateProposalSummarySuccess() {
  return action(ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_SUCCESS)
}

export function generateProposalSummaryFailed() {
  return action(ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_FAILURE)
}

export function generateTermsheetPDFRequest(
  proposal: Proposal,
  termsheet: TermsheetStructure,
  callback: (item: any) => void
) {
  return action(
    ActionTypes.GENERATE_TERMSHEET_PDF_REQUEST,
    { proposal, termsheet },
    { callback }
  )
}

export function generateTermsheetSuccess() {
  return action(ActionTypes.GENERATE_TERMSHEET_PDF_SUCCESS)
}

export function generateTermsheetFailed() {
  return action(ActionTypes.GENERATE_TERMSHEET_PDF_FAILURE)
}

export function loadTermsheetRequest(
  proposal: Proposal,
  callback: (item: TermsheetStructure) => void
) {
  return action(
    ActionTypes.LOAD_TERMSHEET_REQUEST,
    { proposal },
    { callback }
  )
}

export function loadTermsheetSuccess() {
  return action(ActionTypes.LOAD_TERMSHEET_SUCCESS)
}

export function loadTermsheetFailed() {
  return action(ActionTypes.LOAD_TERMSHEET_FAILURE)
}

// Reference rate
export function loadReferenceRateRequest(proposal: Proposal) {
  return action(ActionTypes.LOAD_REFERENCE_RATE_REQUEST, { proposal })
}

export function loadReferenceRateSuccess(referenceRate: ReferenceRate) {
  return action(ActionTypes.LOAD_REFERENCE_RATE_SUCCESS, { referenceRate })
}

export function loadReferenceRateFailed() {
  return action(ActionTypes.LOAD_REFERENCE_RATE_FAILED)
}

export function resetReferenceRate() {
  return action(ActionTypes.RESET_REFERENCE_RATE)
}
