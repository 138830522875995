import * as React from 'react'

import Main from '../main'
import { refreshAzureAccessToken } from '../../services/api/azure-ad'

interface Props {
  isStorageSet: boolean
  onLoadUserProfile: () => void
}

function AppWrapper(props: Props) {
  React.useEffect(() => {
    if (props.isStorageSet) {
      refreshAzureAccessToken(props.onLoadUserProfile)
    }
  }, [props.isStorageSet, props.onLoadUserProfile])

  return <Main />
}

export default AppWrapper
