import { ax, createHeader, toSnakeCase, toCamelCase } from "./util"

import { Loan, InterestRate, Period } from "../../store/loan/types"

export async function createLoan(loan: Loan) {
  const data = toSnakeCase(loan)
  const response = await ax.post(`/loans/`, data, createHeader())
  console.assert(response.status === 201)
  return toCamelCase(response.data)
}

export async function updateLoan(loan: Loan) {
  const data = toSnakeCase(loan)
  const response = await ax.patch(`/loans/${loan.id}/`,
    data, createHeader())
  console.assert(response.status === 200)
  return toCamelCase(response.data)
}

export async function deleteLoan(loanId: number) {
  const response = await ax.delete(`/loans/${loanId}/`, createHeader())
  console.assert(response.status === 204)
  return response
}

export async function getClientLoans(clientId: number): Promise<Loan[]> {
  const response = await ax.get(`/loans/get_for_client/?client_id=${clientId}`, createHeader())
  console.assert(response.status === 200)
  return toCamelCase(response.data)
}

export async function getLoan(loanId: number): Promise<Loan> {
  const response = await ax.get(`/loans/${loanId}/`, createHeader())
  console.assert(response.status === 200)
  return toCamelCase(response.data)
}

export async function adjustSchedule(
  interestRate: InterestRate,
  schedule: Period[]
): Promise<Period[]> {
  const data = toSnakeCase({ interestRate, schedule })
  const response = await ax.post(`/schedule/adjust_schedule/`, data, createHeader())
  console.assert(response.status === 200)
  return toCamelCase(response.data.schedule)
}

export async function getLoans(): Promise<Loan[]> {
  const response = await ax.get(`/loans/`, createHeader())
  console.assert(response.status === 200)
  return toCamelCase(response.data)
}
