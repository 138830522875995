import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import ActionTypes from './types'
import { Client } from '../types'

type ClientAction = ActionType<typeof actions>

const initialState = {
  clientList: [] as Client[],
  deleteFailure: false,
  busyCount: 0,
}

export type ClientListState = typeof initialState

const reducer: Reducer<ClientListState, ClientAction> =
  (state = initialState, action: ClientAction) => {
    switch (action.type) {

      case ActionTypes.GET_CLIENT_LIST_REQUEST: {
        return {
          ...state,
          busyCount: state.busyCount + 1,
        }
      }

      case ActionTypes.GET_CLIENT_LIST_SUCCESS: {
        const clients = action.payload
        return {
          ...state,
          clientList: clients,
          busyCount: state.busyCount - 1,
        }
      }

      case ActionTypes.GET_CLIENT_LIST_FAILURE: {
        return {
          ...state,
          busyCount: state.busyCount - 1,
        }
      }

      case ActionTypes.DELETE_CLIENT_FAILURE:
        return {
          ...state,
          deleteFailure: true
        }

      case ActionTypes.DELETE_CLIENT_FAILURE_DONE:
        return {
          ...state,
          deleteFailure: false
        }

      default:
        return state
    }
  }

export default reducer
