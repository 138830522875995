

export enum Dir {
  DOWN = 'DOWN',
  FLAT = 'FLAT',
  RISE = 'RISE',
  CSTM = 'CSTM',
  NULL = 'NULL',
}

export type Direction = [Dir, Dir, Dir]
export type FactorType = 1 | 2 | 3
export type PartyType = 'bank' | 'client' | 'counter_party'
export type IncrementType = 'up' | 'flat' | 'down'
export type SummaryType = 'loan' | 'hedge' | 'total'


export interface RiskFactor {
  initial: number
  change: number
}

export interface CustomRiskFactor {
  id: number | undefined
  proposalId: number | undefined
  index: string | null
  tenor: number | null
  currencyPair: string | null
  values: CustomRiskValue[]
}

export type CustomRiskValue = {
  tenor: number
  value: number
  isChanged: boolean
}

type RiskFactors = (RiskFactor | null)[]

export interface Scenario {
  direction: Direction
  riskFactors: RiskFactors
  customRiskFactors: CustomRiskFactor[]
}

export interface ScenarioSummary {
  scenario: Scenario
  loan: number
  hedge: number
  total: number
}

export interface CashflowInput {
  proposalId: number
  asOf: string
  currency: string
  scenario: Scenario
}

export interface ScenarioCashflow {
  start: number,
  end: number,
  hedgeNet: number,
  payLeg: number,
  receiveLeg: number,
  loanPrin: number,
  loanInterest: number,
  loanNet: number,
  totalNet: number,
}

export interface ScenarioSettings {
  direction: number[]
  riskFactors: Series[]
}

export interface Series {
  initial: number
  change: number
}

export interface ScenarioData {
  settings: ScenarioSettings
  details: {}
  summary: ScenarioSummary
}

export interface ScenarioValueItem {
  value: number
  enabled?: boolean
}

export interface FactorIncrement {
  increment: IncrementType
  incrementValue: number
  enabled: boolean
}
