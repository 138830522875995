import { Configuration } from 'msal'
import { MsalAuthProvider, LoginType } from "react-aad-msal"
import * as Msal from 'msal'
import { AuthenticationParameters } from "msal"
import * as jwt from "../auth"
import { deleteLockdownClient } from '../../store/auth/reducer'

const config: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    clientId: `${process.env.REACT_APP_AZURE_API_ID}`,
    redirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
}


const authenticationParameters: AuthenticationParameters = {
  scopes: [
    `${process.env.REACT_APP_AZURE_RELYING_PARTY_ID}/IRD.read`
  ]
}

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)

export const refreshAzureAccessToken = async (callback?: any) => {
  try {
    await authProvider.getAccessToken().then(
      response => {
        const { accessToken } = response
        jwt.saveToken(accessToken)
        if (callback && isFunction(callback)) callback()
      })
  } catch (error) {
    console.error(error)
    loggingOut()
  }
}


export const loggingOut = () => {
  const logoutConf = {
    ...config, auth: {
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
      clientId: `${process.env.REACT_APP_AZURE_API_ID}`,
      redirectUri: window.location.origin + '/auth/logout-success',
      validateAuthority: true,
      navigateToLoginRequestUrl: false,
    }
  }
  const userAgentApplication = new Msal.UserAgentApplication(logoutConf)
  jwt.deleteToken()
  deleteLockdownClient()
  userAgentApplication.logout()
}

function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function isAzureAD(): boolean {
  let isAzureAD = process.env.REACT_APP_AZURE_AD
  return isAzureAD && isAzureAD === '1' ? true : false
}
