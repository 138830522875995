import { call, put, takeEvery } from "@redux-saga/core/effects"
import * as accountApi from '../../../services/api/account'
import { ActionTypes } from '../action-types'
import { BaseAction } from "../../../services/action"
import {
  loadAccountSuccess,
  calculateCardsRequest,
  loadAccountRequest,
  loadAccountFailure
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../util"
import { isAzureAD } from "../../../services/api/azure-ad"

export function* watchLoadAccount() {
  yield takeEvery(ActionTypes.LOAD_ACCOUNT_REQUEST, loadAccount)
}

/** Load the Account associated with the given Proposal, and calculate cards. */
function* loadAccount(action: BaseAction) {
  const { accountId } = action.payload
  try {
    const account = yield call(accountApi.loadAccount, accountId)
    yield put(calculateCardsRequest(account))
    yield put(loadAccountSuccess(account))
  } catch (error) {
    console.error(error)
    yield put(loadAccountFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadAccountRequest(accountId))
    }
  }
}
