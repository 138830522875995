import { watchLoadProposal } from './load-proposal'
import { watchRecalcProposalPrice } from './recalc-proposal-price'
import { watchDeleteProposal } from './delete-proposal'
import { watchUpdateProposal } from './update-proposal'
import { watchRefreshProposal } from './refresh-proposal'
import { watchSaveActiveCard } from './save-activecard'
import { watchUpdateLoan } from './update-loan'
import { watchUpdatePreconfiguredLoan } from './update-preconfigured-loan'
import { watchUpdateHedge } from './update-hedge'
import { watchCalculatePriceDetail } from './calculate-price-detail'
import { watchUpdateProposalPrice } from './update-price-detail'
import { watchLoadProposalSummaryPDF, watchLoadTermsheetPDF } from './load-pdf'
import { watchLoadTermsheet } from './load-termsheet'
import { watchUpdateDiscussionSummary } from './update-summary'
import { watchRequestDiscussionSummaryReport } from './request-summary-report'
import { watchLoadReferenceRate } from './load-reference-rate'

export default [
  watchLoadProposal,
  watchRecalcProposalPrice,
  watchDeleteProposal,
  watchUpdateProposal,
  watchRefreshProposal,
  watchSaveActiveCard,
  watchUpdateLoan,
  watchUpdatePreconfiguredLoan,
  watchUpdateHedge,
  watchCalculatePriceDetail,
  watchUpdateProposalPrice,
  watchLoadProposalSummaryPDF,
  watchLoadTermsheetPDF,
  watchLoadTermsheet,
  watchUpdateDiscussionSummary,
  watchRequestDiscussionSummaryReport,
  watchLoadReferenceRate,
]
