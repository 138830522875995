import * as React from 'react'
import { connect } from 'react-redux'

import { loginStatus } from '../../store/auth/selectors'
import { AppState } from '../../store'
import {
  logoutRequest, statusLoggingOut, refreshTokenRequest, loadUserProfileRequest
} from '../../store/auth/actions'
import App from './app'

const mapStateToProps = (state: AppState) => {
  return {
    loginStatus: loginStatus(state),
  }
}

const mapDispatchToProps = {
  statusLoggingOut,
  logout: logoutRequest,
  refreshToken: refreshTokenRequest,
  loadUserProfile: loadUserProfileRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App))

