import { action } from 'typesafe-actions'

import { ActionTypes } from '../action-types'

export function logoutRequest() {
  return action(ActionTypes.LOGOUT_REQUEST)
}

export function logoutSuccess() {
  return action(ActionTypes.LOGOUT_SUCCESS)
}
