import { AppState } from ".."
import { LoginStatus, ChangePwdStatus, UserProfile } from "./types"
import { Client } from "../client"
import { getCurrencyChoices } from "../../services/util"

// Selectors

export function getEmail(state: AppState): string {
  return state.auth.email
}

export function getError(state: AppState): string {
  return state.auth.error
}

export function loginStatus(state: AppState): LoginStatus {
  return state.auth.status
}

export function getChangePwdErrors(state: AppState): string[] {
  return state.auth.changePwdError
}

export function changePwdStatus(state: AppState): ChangePwdStatus {
  return state.auth.statusChangePwd
}

export function isLoggedOut(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.LOGGED_OUT
}

export function isLoggingIn(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.LOGGING_IN
}

export function isLoggedIn(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.LOGGED_IN
}

export function isLogFailed(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.LOGIN_FAILED
}

export function isLoggingOut(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.LOGGING_OUT
}

export function isExpired(state: AppState): boolean {
  return loginStatus(state) === LoginStatus.EXPIRED
}

export function getLockdownClient(state: AppState): Client | undefined {
  return state.auth.lockdownClient
}

export function getUserProfile(state: AppState): UserProfile | undefined {
  return state.auth.me
}

export function getDefaultCurrencies(state: AppState): string[] {
  const userProfile = state.auth.me
  let currencies = userProfile ? userProfile.defaultCurrencies : []
  if (currencies.length === 0)
    currencies = getCurrencyChoices()

  return currencies
}
