import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import { loadCustomRiskFactorSuccess, loadCustomRiskFactorFailure, loadCustomRiskFactorRequest } from '../actions'
import { CustomRiskFactor } from './../../types'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchLoadCustomRiskFactor() {
  yield takeEvery(ActionTypes.LOAD_CUSTOM_RISK_FACTOR_REQUEST, loadCustomRiskFactor)
}

function* loadCustomRiskFactor(action: BaseAction) {
  const { proposalId } = action.payload
  try {
    let results: CustomRiskFactor[] = yield call(api.loadCustomRiskFactor, proposalId)

    // Set isChanged to false
    results.forEach(result => {
      result.values.forEach(value => { value.isChanged = false })
    })

    yield put(loadCustomRiskFactorSuccess(results))
  } catch (error) {
    console.log(error)
    yield put(loadCustomRiskFactorFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadCustomRiskFactorRequest(proposalId))
    }
  }
}
