import { all, spawn, call, delay } from "@redux-saga/core/effects"

import auth from './auth/sagas'
import clientDetail from './client/client-edit/sagas'
import clientList from './client/client-list/sagas'
import proposalDetail from './proposal/proposal-detail/sagas'
import proposalList from './proposal/proposal-list/sagas'
import quickscan from './quickscan/sagas'
import scenarioData from './scenario/data/sagas'

const rootSagas = auth
  .concat(clientDetail)
  .concat(clientList)
  .concat(proposalDetail)
  .concat(proposalList)
  .concat(quickscan)
  .concat(scenarioData)

/**
 * See https://stackoverflow.com/questions/39438005/what-is-the-idiomatic-way-of-starting-rootsaga
 * Wraps a saga with try-catch in case it leaks exceptions
 * and re-invokes (using spawn) the saga when it crashes.
 */
function makeRestartable(saga: any) {
  return function* () {
    yield spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          console.error('unexpected root saga termination. ' +
            'The root sagas are supposed to be sagas that ' +
            'live during the whole app lifetime!', saga)
        } catch (e) {
          console.error('Saga error, the saga will be restarted', e)
        }
        yield delay(1000) // Workaround to avoid infinite error loops
      }
    })
  }
}

const restartableSagas = rootSagas.map(makeRestartable)

export default function* root() {
  // yield all(rootSagas.map(saga => spawn(saga))) // Works
  yield all(restartableSagas.map(saga => call(saga))) // Works better ;-)
}
