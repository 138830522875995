import { call, put, takeEvery } from "@redux-saga/core/effects"

import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import * as proposalSummaryApi from '../../../../services/api/proposal-summary'
import { DiscussionSummary } from "../../types"
import {
  refreshProposalRequest,
  updateDiscussionSummarySuccess,
  updateDiscussionSummaryFailed,
  updateDiscussionSummaryRequest
} from "../../../proposal/proposal-detail/actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchUpdateDiscussionSummary() {
  yield takeEvery(ActionTypes.UPDATE_DISCUSSION_SUMMARY_REQUEST, updateDiscussionSummary)
}

function* updateDiscussionSummary(action: BaseAction) {
  const summary: DiscussionSummary = action.payload.summary
  const id: number = action.payload.id
  try {
    yield call(
      proposalSummaryApi.updateDiscussionSummary,
      summary,
      summary.id,
    )
    yield put(updateDiscussionSummarySuccess())
    yield put(refreshProposalRequest(id))
  } catch (error) {
    yield put(updateDiscussionSummaryFailed())
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateDiscussionSummaryRequest(summary, id))
    }
  }
}
