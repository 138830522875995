import { Client } from "../../client"

enum ActionTypes {
  LOAD_PROPOSALS_REQUEST = "@@proposal/LOAD_PROPOSALS_REQUEST",
  LOAD_PROPOSALS_SUCCESS = "@@proposal/LOAD_PROPOSALS_SUCCESS",
  LOAD_PROPOSALS_FAILURE = "@@proposal/LOAD_PROPOSALS_FAILURE",
  LOAD_CLIENT_REQUEST = "@@proposal/LOAD_CLIENT_REQUEST",
  LOAD_CLIENT_SUCCESS = "@@proposal/LOAD_CLIENT_SUCCESS",
  LOAD_CLIENT_FAILURE = "@@proposal/LOAD_CLIENT_FAILURE",
  LOAD_ACTIVITY_LOG_REQUEST = "@@proposal/LOAD_ACTIVITY_LOG_REQUEST",
  LOAD_ACTIVITY_LOG_SUCCESS = "@@proposal/LOAD_ACTIVITY_LOG_SUCCESS",
  LOAD_ACTIVITY_LOG_FAILURE = "@@proposal/LOAD_ACTIVITY_LOG_FAILURE",
  LOAD_SUMMARY_LIST_REQUEST = "@@proposal/LOAD_SUMMARY_LIST_REQUEST",
  LOAD_SUMMARY_LIST_SUCCESS = "@@proposal/LOAD_SUMMARY_LIST_SUCCESS",
  LOAD_SUMMARY_LIST_FAILURE = "@@proposal/LOAD_SUMMARY_LIST_FAILURE",
  GENERATE_PAST_PROPOSAL_PDF_REQUEST = "@@proposal/GENERATE_PAST_PROPOSAL_PDF_REQUEST",
  GENERATE_PAST_PROPOSAL_PDF_SUCCESS = "@@proposal/GENERATE_PAST_PROPOSAL_PDF_SUCCESS",
  GENERATE_PAST_PROPOSAL_PDF_FAILURE = "@@proposal/GENERATE_PAST_PROPOSAL_PDF_FAILURE",
}

export default ActionTypes

export interface Proposal {
  id: number
  client: Client
  creator: string
  updated: string
  hedgeFrom: string
  hedgeTo: string
  instrument: string
}

export interface ActivityData {
  activeCardId: number
  loanId: number
  hedgeId: number
}
/** Secret data only structurer able to see */
export interface AdditionalData {
  pricingDate: string
  tradeDate: string
  margin: string
  finalPrice: string
}

export interface ActivityLog {
  id: number
  proposalId: number
  timestamp: string
  activityType: string
  data: ActivityData
  additionalData: AdditionalData
}

export interface SummaryHistory {
  id: number
  proposalId: number
  timestamp: string
  docType: string
}
