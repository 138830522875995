import * as yup from 'yup'

export function createValidateSchema(): any {
  const rules = {
    email: yup.string()
      .email('Email is invalid.')
      .required('Email is required.'),
    password: yup
      .string()
      .min(8, 'Must be at least 8 characters.')
      .required('Password is required.'),
  }
  return yup.object().shape(rules)
}
