import { call, put, delay } from "@redux-saga/core/effects"
import { refreshAzureAccessToken } from "../services/api/azure-ad"

export function calcBusyCount(currentBusyCount: number, change: 1 | -1): number {
	if (change === -1) {
		return (currentBusyCount > 0) ? currentBusyCount + change : 0
	} else {
		return currentBusyCount + change
	}
}

export function* renewAzureTokenAndPut(action: any) {
	yield call(refreshAzureAccessToken)
	yield delay(100)
	yield put(action)
}

export function isInvalidTokenError(error: any) {
	return (
		(
			error &&
			error.response &&
			error.response.data &&
			error.response.data.detail === 'Invalid access token.'
		)
		||
		`${error}`.search("Invalid access token") > -1
		||
		`${error}`.search("Request failed with status code 401") > -1
	)
}
