import { ax, createHeader, toSnakeCase, toCamelCase } from "./util"
import { Hedge } from "../../store/hedge/types"

export async function createHedge(hedge: Hedge) {
  const data = toSnakeCase(hedge)
  const response = await ax.post(`/hedges/`, data, createHeader())
  console.assert(response.status === 201)
  const newHedge = toCamelCase(response.data)
  return newHedge
}

export async function updateHedge(hedge: Hedge) {
  const copy = { ...hedge }
  const data = toSnakeCase(copy)
  const response = await ax.patch(`/hedges/${hedge.id}/`,
    data, createHeader())
  console.assert(response.status === 200)
  const newHedge = toCamelCase(response.data)
  return newHedge
}

export async function deleteHedge(hedgeId: number) {
  const response = await ax.delete(`/hedges/${hedgeId}/`, createHeader())
  console.assert(response.status === 204)
  return response
}

/**
 * Create a new loan on server that fully hedges the loan, and
 * return the new loan.
 */
export async function createHedgeFromLoan(
  loanId: number,
  cardId: number): Promise<Hedge> {

  const data = {
    loanId,
    cardId,
  }

  const response = await ax.post(
    '/hedges/create_from_loan/',
    toSnakeCase(data),
    createHeader())

  console.assert(response.status === 200)
  // return toCamelCase(response.data)
  // TODO: Temporary fix until backend is ready
  return augment_spot(toCamelCase(response.data))
}

function augment_spot(hedge: Hedge): Hedge {
  return {
    ...hedge,
    fxSpot: 33.5940,
  }
}
