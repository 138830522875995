import { call, takeEvery, put } from '@redux-saga/core/effects'

import { ActionTypes } from '../action-types'
import * as api from '../../../services/api/auth'
import {
  loadUserProfileSuccess,
  loadUserProfileRequest
} from '../actions/user-profile'
import { UserProfile } from '../types'
import { isInvalidTokenError, renewAzureTokenAndPut } from '../../util'
import { isAzureAD } from '../../../services/api/azure-ad'

export function* watchLoadUserProfile() {
  yield takeEvery(ActionTypes.LOAD_USER_PROFILE_REQUEST, loadUserProfile)
}

function* loadUserProfile() {
  try {
    const response: UserProfile = yield call(api.loadUserProfile)
    const me = response
    yield put(loadUserProfileSuccess(me))
  }
  catch (error) {
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadUserProfileRequest())
    }
  }
}
