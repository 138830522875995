import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../services/api/proposal'
import * as clientApi from '../../../services/api/client'
import ActionTypes, { Proposal } from './types'
import {
  loadProposalsSuccess,
  loadClientSuccess,
  loadProposalsFailure,
  loadActivityLogFailure,
  loadActivityLogSuccess,
  loadSummaryListFailure,
  loadSummaryListSuccess,
  generatePastProposalPDFSuccess,
  generatePastProposalPDFFailure,
  loadProposalsRequest,
  loadClientRequest,
  loadSummaryListRequest,
  generatePastProposalPDFRequest,
  loadActivityLogRequest,
  loadClientFailure
} from "./actions"
import { BaseAction } from "../../../services/action"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../util"
import { isAzureAD } from "../../../services/api/azure-ad"

function* watchLoadProposals() {
  yield takeEvery(ActionTypes.LOAD_PROPOSALS_REQUEST, loadProposals)
}

export function* loadProposals() {
  try {
    const proposals: Proposal[] = yield call(api.getProposals)
    yield put(loadProposalsSuccess(proposals))
  } catch (error) {
    console.error(error)
    yield put(loadProposalsFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadProposalsRequest())
    }
  }
}

function* watchLoadClient() {
  yield takeEvery(ActionTypes.LOAD_CLIENT_REQUEST, loadClient)
}

export function* loadClient(action: BaseAction) {
  const { clientId } = action.payload
  try {
    let client = yield call(clientApi.getClient, clientId)
    yield put(loadClientSuccess(client))
  } catch (error) {
    console.error(error)
    yield put(loadClientFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadClientRequest(clientId))
    }
  }
}

function* watchLoadActivityLog() {
  yield takeEvery(ActionTypes.LOAD_ACTIVITY_LOG_REQUEST, loadActivityLog)
}

export function* loadActivityLog(action: BaseAction) {
  const { proposalId } = action.payload
  try {
    let response = yield call(api.loadActivityLogs, proposalId)
    yield put(loadActivityLogSuccess())
    action.meta.callback(response)
  } catch (error) {
    console.error(error)
    yield put(loadActivityLogFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadActivityLogRequest(proposalId, action.meta.callback))
    }
  }
}

function* watchLoadSumaryList() {
  yield takeEvery(ActionTypes.LOAD_SUMMARY_LIST_REQUEST, loadSummaryList)
}

export function* loadSummaryList(action: BaseAction) {
  const { proposalId } = action.payload
  try {
    let response = yield call(api.loadSummaryLists, proposalId)
    yield put(loadSummaryListSuccess())
    action.meta.callback(response)
  } catch (error) {
    console.error(error)
    yield put(loadSummaryListFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadSummaryListRequest(proposalId, action.meta.callback))
    }
  }
}

function* watchLoadPastProposal() {
  yield takeEvery(ActionTypes.GENERATE_PAST_PROPOSAL_PDF_REQUEST, loadPastProposal)
}

export function* loadPastProposal(action: BaseAction) {
  const { id } = action.payload
  try {
    let response = yield call(api.loadPastProposalPDF, id)
    yield put(generatePastProposalPDFSuccess())
    action.meta.callback(response)
  } catch (error) {
    console.error(error)
    yield put(generatePastProposalPDFFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(generatePastProposalPDFRequest(id, action.meta.callback))
    }
  }
}

export default [
  watchLoadProposals,
  watchLoadClient,
  watchLoadActivityLog,
  watchLoadSumaryList,
  watchLoadPastProposal,
]
