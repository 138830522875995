import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { Proposal } from "../.."
import { refreshProposalSuccess, refreshProposalFailed, refreshProposalRequest } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchRefreshProposal() {
  yield takeEvery(ActionTypes.REFRESH_PROPOSAL_REQUEST, refreshProposal)
}

function* refreshProposal(action: BaseAction) {
  const proposalId = action.payload as number
  try {
    const proposal: Proposal = yield call(api.getProposal, proposalId)
    yield put(refreshProposalSuccess(proposal))
  } catch (error) {
    yield put(refreshProposalFailed())
    console.log(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(refreshProposalRequest(proposalId))
    }
  }
}
