
enum ActionTypes {
  CALCULATE_SUMMARY_REQUEST = "@@scenario/CALCULATE_SUMMARY_REQUEST",
  CALCULATE_SUMMARY_SUCCESS = "@@scenario/CALCULATE_SUMMARY_SUCCESS",
  CALCULATE_SUMMARY_FAILURE = "@@scenario/CALCULATE_SUMMARY_FAILURE",
  CALCULATE_CASHFLOW_REQUEST = "@@scenario/CALCULATE_CASHFLOW_REQUEST",
  CALCULATE_CASHFLOW_SUCCESS = "@@scenario/CALCULATE_CASHFLOW_SUCCESS",
  CALCULATE_CASHFLOW_FAILURE = "@@scenario/CALCULATE_CASHFLOW_FAILURE",
  LOAD_CUSTOM_RISK_FACTOR_REQUEST = "@@scenario/LOAD_CUSTOM_RISK_FACTOR_REQUEST",
  LOAD_CUSTOM_RISK_FACTOR_SUCCESS = "@@scenario/LOAD_CUSTOM_RISK_FACTOR_SUCCESS",
  LOAD_CUSTOM_RISK_FACTOR_FAILURE = "@@scenario/LOAD_CUSTOM_RISK_FACTOR_FAILURE",
  CREATE_CUSTOM_RISK_FACTOR_REQUEST = "@@scenario/CREATE_CUSTOM_RISK_FACTOR_REQUEST",
  CREATE_CUSTOM_RISK_FACTOR_SUCCESS = "@@scenario/CREATE_CUSTOM_RISK_FACTOR_SUCCESS",
  CREATE_CUSTOM_RISK_FACTOR_FAILURE = "@@scenario/CREATE_CUSTOM_RISK_FACTOR_FAILURE",
  UPDATE_CUSTOM_RISK_FACTOR_REQUEST = "@@scenario/UPDATE_CUSTOM_RISK_FACTOR_REQUEST",
  UPDATE_CUSTOM_RISK_FACTOR_SUCCESS = "@@scenario/UPDATE_CUSTOM_RISK_FACTOR_SUCCESS",
  UPDATE_CUSTOM_RISK_FACTOR_FAILURE = "@@scenario/UPDATE_CUSTOM_RISK_FACTOR_FAILURE",
  DELETE_CUSTOM_RISK_FACTOR_REQUEST = "@@scenario/DELETE_CUSTOM_RISK_FACTOR_REQUEST",
  DELETE_CUSTOM_RISK_FACTOR_SUCCESS = "@@scenario/DELETE_CUSTOM_RISK_FACTOR_SUCCESS",
  DELETE_CUSTOM_RISK_FACTOR_FAILURE = "@@scenario/DELETE_CUSTOM_RISK_FACTOR_FAILURE",
}

export default ActionTypes
