import { combineReducers } from 'redux'
import loanList, { LoanListState } from './loan-list/reducer'
import loanDetail, { LoanDetailState } from './loan-detail/reducer'

export interface LoanState {
  loanList: LoanListState
  loanDetail: LoanDetailState
}

export default combineReducers<LoanState>({
  loanList,
  loanDetail,
})
