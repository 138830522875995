import { library } from '@fortawesome/fontawesome-svg-core'
import { faLockOutline } from './custom-icon'
import {
  faCalendarAlt,
  faFileSignature,
  faPencilAlt,
  faTrashAlt,
  faEdit,
  faPen,
  faTrash,
  faClipboardList,
  faFileInvoice,
  faLock,
  faLockOpen,
  faPlus,
  faCheck,
  faExclamationCircle,
  faCog,
  faTimes,
  faArrowRight,
  faCopy,
  faSave,
  faDownload,
  faSearch,
  faQuestionCircle,
  faChevronLeft,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import * as CustomIcon from './custom-icon'

const customIcons = [
  CustomIcon.faCustomChart
]

const utilityIcons = [
  faCalendarAlt,
  faFileSignature,
  faPencilAlt,
  faTrashAlt,
  faCheck,
  faEdit,
  faPen,
  faTrash,
  faClipboardList,
  faFileInvoice,
  faLock,
  faLockOpen,
  faPlus,
  faExclamationCircle,
  faCog,
  faTimes,
  faArrowRight,
  faCopy,
  faSave,
  faDownload,
  faSearch,
  faQuestionCircle,
  faChevronLeft,
  faLockOutline,
  faFilePdf,
]

export function initIcons() {
  utilityIcons.forEach(icon => library.add(icon))
  customIcons.forEach(icon => library.add(icon))
}
