// JWT and auth-related functions
import jwtDecode from 'jwt-decode'
import { LoginStatus } from '../store/auth/types'

export function decode(token: string) {
  return jwtDecode(token)
}

export function saveToken(token: string) {
  localStorage.setItem('jwtToken', token)
}

export function deleteToken() {
  localStorage.removeItem('jwtToken')
}

export function getToken() {
  return localStorage.getItem('jwtToken') || ''
}

function tokenValidity(token: any) {
  if (!token || !token.exp) {
    return 'invalid'
  }
  else {
    const expiry = new Date(token.exp * 1000)
    const now = new Date()
    if (expiry < now)
      return 'expired'
  }

  return 'valid'
}

export function isTokenValid(token: string) {
  if (!token)
    return false

  const decoded = jwtDecode(token)

  return tokenValidity(decoded) === 'valid'
}

/** Refresh the token if currently logged out but an unexpired token exists */
export function refreshToken(
  loginStatus: LoginStatus,
  refreshTokenFunc: (token: string) => void) {

  const token = getToken()

  if (isTokenValid(token) && loginStatus === LoginStatus.LOGGED_OUT) {
    refreshTokenFunc(token)
  }
}
