import { combineReducers } from 'redux'

import proposalList, { ProposalListState } from './proposal-list/reducer'
import proposalDetail, { ProposalDetailState } from './proposal-detail/reducer'

import { Proposal, PriceDetail } from './types'
export type Proposal = Proposal
export type PriceDetail = PriceDetail

export interface ProposalState {
  proposalList: ProposalListState
  proposalDetail: ProposalDetailState
}

export default combineReducers<ProposalState>({
  proposalList,
  proposalDetail,
})
