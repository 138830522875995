import { ax, createHeader, toSnakeCase } from './util'
import { Client } from '../../store/client/types'


export async function createClient(client: Client): Promise<Client> {
  const data = toSnakeCase(client)
  const json = await ax.post('/clients/', data, createHeader())
  console.assert(json.status === 201)
  return json.data
}

export async function getClient(id: number): Promise<Client> {
  const json = await ax.get(`/clients/${id}/`, createHeader())
  console.assert(json.status === 200)
  return json.data
}


export async function getClientList(): Promise<Client[]> {
  const json = await ax.get('/clients/', createHeader())
  console.assert(json.status === 200)
  return json.data
}

export async function deleteClient(id: number) {
  const response = await ax.delete(`/clients/${id}/`, createHeader())
  console.assert(response.status === 204)
}

export async function updateClient(client: Client) {
  const data = toSnakeCase(client)
  const response = await ax.patch(`/clients/${client.id}/`, data, createHeader())
  console.assert(response.status === 200)
}
