import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { Proposal } from "../.."
import { loadProposalSuccess, loadProposalFailure, loadProposalRequest } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchLoadProposal() {
  yield takeEvery(ActionTypes.LOAD_PROPOSAL_REQUEST, loadProposal)
}

function* loadProposal(action: BaseAction) {
  try {
    const proposalId = action.payload as number
    const proposal: Proposal = yield call(api.getProposal, proposalId)
    yield put(loadProposalSuccess(proposal))
  } catch (error) {
    yield put(loadProposalFailure())
    console.log(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadProposalRequest(action.payload))
    }
  }

}
