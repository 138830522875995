import * as React from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'

import App from './app'
import AppAzure from './app/app-azure'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import AzureAD from 'react-aad-msal'
import { authProvider, isAzureAD } from '../services/api/azure-ad'

interface RootProps {
  store: Store
}

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto", "Helvetica", "Arial", "sans-serif"
    ].join(',')
  },
  palette: {
    primary: {
      main: '#37474f',
    },
    secondary: {
      main: '#cfd8dc',
    }
  },
})

const Root = ({ store }: RootProps) => (
  isAzureAD() ?
    <AzureAD provider={authProvider} forceLogin={true} >
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <AppAzure />
        </MuiThemeProvider>
      </Provider>
    </AzureAD>
    :
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
)

export default Root
