import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { recalcProposalPriceSuccess, recalcProposalPriceFailure, recalcProposalPriceRequest } from "../actions"
import { Proposal } from "../../../types"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


export function* watchRecalcProposalPrice() {
  yield takeEvery(ActionTypes.RECALC_PROPOSAL_PRICE_REQUEST, recalculate)
}

function* recalculate(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const callback: any = action.payload.callback

  try {
    const updated = yield call(api.recalcProposalPrice, proposal.id)
    yield put(recalcProposalPriceSuccess(updated))
    if (callback)
      callback()
  }
  catch (error) {
    console.error(error)
    yield put(recalcProposalPriceFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(recalcProposalPriceRequest(proposal, callback))
    }
  }
}
