import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import ActionTypes, { Proposal, ActivityLog, SummaryHistory } from './types'
import { deepClone } from '../../../services/util'
import { Client } from '../../client'

type Action = ActionType<typeof actions>

const initialState = {
  proposals: [] as Proposal[],
  busyCount: 0,
  activityLogBusyCount: 0,
  summaryListBusyCount: 0,
  pastProposalPDFBusyCount: 0,
  activityLogs: [] as ActivityLog[],
  summaryList: [] as SummaryHistory[],
  client: undefined as Client | undefined,
}

export type ProposalListState = typeof initialState

function reducer(state: ProposalListState = initialState, action: Action): ProposalListState {
  switch (action.type) {
    case ActionTypes.LOAD_PROPOSALS_REQUEST:
      return {
        ...state,
        busyCount: state.busyCount + 1,
      }
    case ActionTypes.LOAD_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: deepClone(action.payload),
        busyCount: state.busyCount - 1,
      }
    case ActionTypes.LOAD_PROPOSALS_FAILURE:
      return {
        ...state,
        busyCount: state.busyCount - 1,
      }
    case ActionTypes.LOAD_ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        activityLogBusyCount: state.activityLogBusyCount + 1,
      }
    case ActionTypes.LOAD_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLogBusyCount: state.activityLogBusyCount - 1,
      }
    case ActionTypes.LOAD_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        activityLogBusyCount: state.activityLogBusyCount - 1,
      }
    case ActionTypes.LOAD_SUMMARY_LIST_REQUEST:
      return {
        ...state,
        summaryListBusyCount: state.summaryListBusyCount + 1,
      }
    case ActionTypes.LOAD_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        summaryListBusyCount: state.summaryListBusyCount - 1,
      }
    case ActionTypes.LOAD_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        summaryListBusyCount: state.summaryListBusyCount - 1,
      }
    case ActionTypes.GENERATE_PAST_PROPOSAL_PDF_REQUEST:
      return {
        ...state,
        pastProposalPDFBusyCount: state.pastProposalPDFBusyCount + 1,
      }
    case ActionTypes.GENERATE_PAST_PROPOSAL_PDF_SUCCESS:
      return {
        ...state,
        pastProposalPDFBusyCount: state.pastProposalPDFBusyCount - 1,
      }
    case ActionTypes.GENERATE_PAST_PROPOSAL_PDF_FAILURE:
      return {
        ...state,
        pastProposalPDFBusyCount: state.pastProposalPDFBusyCount - 1,
      }
    case ActionTypes.LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        client: deepClone(action.payload)
      }
    case ActionTypes.LOAD_CLIENT_FAILURE:
      return {
        ...state,
        busyCount: state.busyCount - 1,
      }

    default:
      return state
  }
}

export default reducer
