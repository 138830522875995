import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as accountApi from '../../../services/api/account'
import { ActionTypes } from '../action-types'
import { BaseAction } from "../../../services/action"
import { updateAccountSuccess, updateAccountRequest, updateAccountFailure } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../util"
import { isAzureAD } from "../../../services/api/azure-ad"

export function* watchUpdateAccount() {
  yield takeEvery(ActionTypes.UPDATE_ACCOUNT_REQUEST, updateAccount)
}

function* updateAccount(action: BaseAction) {
  const { account, callback } = action.payload
  try {
    const updatedAccount = yield call(accountApi.updateAccount, account)

    yield put(updateAccountSuccess(updatedAccount))

    if (callback)
      callback()
  } catch (error) {
    console.error(error)
    yield put(updateAccountFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateAccountRequest(account, callback))
    }
  }
}
