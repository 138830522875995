import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import ActionTypes from './types'
import { Proposal, PriceDetail } from '..'
import { deepClone } from '../../../services/util'
import { ReferenceRate } from '../types'

type Action = ActionType<typeof actions>

const initialState = {
  proposal: undefined as Proposal | undefined,
  isCompletedHedge: false as boolean,
  priceDetail: undefined as PriceDetail | undefined,
  pricingBusyCount: 0,
  discussionSummaryBusyCount: 0,
  busyCount: 0,
  referenceRate: { pay: undefined, receive: undefined } as ReferenceRate
}

export type ProposalDetailState = typeof initialState

function reducer(
  state: ProposalDetailState = initialState,
  action: Action): ProposalDetailState {

  switch (action.type) {

    case ActionTypes.RECALC_PROPOSAL_PRICE_REQUEST:
      return {
        ...state,
        busyCount: state.busyCount + 1,
      }

    case ActionTypes.RECALC_PROPOSAL_PRICE_SUCCESS: {
      const { proposal } = deepClone(action.payload)
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
        proposal,
      }
    }

    case ActionTypes.RECALC_PROPOSAL_PRICE_FAILURE: {
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
      }
    }

    case ActionTypes.LOAD_PROPOSAL_REQUEST:
      // Clear the proposal at start of request
      return { ...state, proposal: undefined }

    case ActionTypes.UPDATE_PROPOSAL_REQUEST:
    case ActionTypes.UPDATE_LOAN_REQUEST:
    case ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_REQUEST:
    case ActionTypes.UPDATE_HEDGE_REQUEST:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, +1),
      }

    case ActionTypes.UPDATE_LOAN_SUCCESS:
    case ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_SUCCESS:
    case ActionTypes.UPDATE_HEDGE_SUCCESS: {
      const { proposal } = deepClone(action.payload)
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
        proposal,
      }
    }

    case ActionTypes.UPDATE_HEDGE_FAILURE:
    case ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_FAILURE:
    case ActionTypes.UPDATE_LOAN_FAILURE:
    case ActionTypes.UPDATE_PROPOSAL_FAILURE:
    case ActionTypes.LOAD_PROPOSAL_FAILURE:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
      }

    case ActionTypes.UPDATE_PROPOSAL_SUCCESS:
    case ActionTypes.LOAD_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposal: deepClone(action.payload),
        busyCount: calcBusyCount(state.busyCount, -1),
      }

    case ActionTypes.REFRESH_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposal: deepClone(action.payload),
      }

    case ActionTypes.UPDATE_PROPOSAL_PRICE_REQUEST:
    case ActionTypes.CALCULATE_PRICE_DETAIL_REQUEST:
      return {
        ...state,
        pricingBusyCount: state.pricingBusyCount + 1
      }

    case ActionTypes.CALCULATE_PRICE_DETAIL_SUCCESS:
      return {
        ...state,
        pricingBusyCount: state.pricingBusyCount - 1,
        priceDetail: action.payload.priceDetail,
      }

    case ActionTypes.CALCULATE_PRICE_DETAIL_FAILURE:
      return {
        ...state,
        pricingBusyCount: state.pricingBusyCount - 1,
      }

    case ActionTypes.UPDATE_PROPOSAL_PRICE_SUCCESS:
      return {
        ...state,
        pricingBusyCount: state.pricingBusyCount - 1,
        proposal: action.payload.proposal,
      }

    case ActionTypes.UPDATE_PROPOSAL_PRICE_FAILURE:
      return {
        ...state,
        pricingBusyCount: state.pricingBusyCount - 1,
      }

    case ActionTypes.SAVE_ACTIVE_CARD_REQUEST:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, +1),
      }

    case ActionTypes.SAVE_ACTIVE_CARD_SUCCESS:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
      }

    case ActionTypes.SAVE_ACTIVE_CARD_FAILURE:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1),
      }

    case ActionTypes.UPDATE_DISCUSSION_SUMMARY_REQUEST:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount + 1
      }
    case ActionTypes.UPDATE_DISCUSSION_SUMMARY_SUCCESS:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount - 1
      }
    case ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_REQUEST:
    case ActionTypes.GENERATE_TERMSHEET_PDF_REQUEST:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount + 1,
      }
    case ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_SUCCESS:
    case ActionTypes.GENERATE_TERMSHEET_PDF_SUCCESS:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount - 1,
      }
    case ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_FAILURE:
    case ActionTypes.GENERATE_TERMSHEET_PDF_FAILURE:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount - 1,
      }
    case ActionTypes.UPDATE_DISCUSSION_SUMMARY_FAILURE:
      return {
        ...state,
        discussionSummaryBusyCount: state.discussionSummaryBusyCount - 1
      }

    case ActionTypes.LOAD_REFERENCE_RATE_SUCCESS: {
      return {
        ...state,
        referenceRate: action.payload.referenceRate
      }
    }

    case ActionTypes.LOAD_REFERENCE_RATE_FAILED: {
      return {
        ...state,
        referenceRate: { pay: undefined, receive: undefined }
      }
    }

    case ActionTypes.RESET_REFERENCE_RATE: {
      return {
        ...state,
        referenceRate: { pay: undefined, receive: undefined }
      }
    }

    default:
      return state
  }
}

/**
 * If change is negative, app will not allow busyCount be negative.
 */
export function calcBusyCount(currentBusyCount: number, change: 1 | -1): number {
  if (change === -1) {
    return (currentBusyCount > 0) ? currentBusyCount + change : 0
  } else {
    return currentBusyCount + change
  }
}

export default reducer
