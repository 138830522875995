enum ActionTypes {
  CREATE_CLIENT_REQUEST = "@@client/CREATE_CLIENT_REQUEST",
  CREATE_CLIENT_SUCCESS = "@@client/CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAILURE = "@@client/CREATE_CLIENT_FAILURE",
  GET_CLIENT_REQUEST = "@@client/GET_CLIENT_REQUEST",
  GET_CLIENT_SUCCESS = "@@client/GET_CLIENT_SUCCESS",
  GET_CLIENT_FAILURE = "@@client/GET_CLIENT_FAILURE",
  UPDATE_CLIENT_REQUEST = "@@client/UPDATE_CLIENT_REQUEST",
  UPDATE_CLIENT_SUCCESS = "@@client/UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE = "@@client/UPDATE_CLIENT_FAILURE",
  DELETE_CLIENT_REQUEST = "@@client/DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS = "@@client/DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE = "@@client/DELETE_CLIENT_FAILURE",
  RESET_ERROR = "@@client/RESET_ERROR",
}

export default ActionTypes
