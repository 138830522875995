import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { ScenarioSummary } from "../../../scenario/types"
import { loadReferenceRateSuccess, loadReferenceRateFailed, loadReferenceRateRequest } from "../actions"
import { ReferenceRate, Proposal } from './../../types'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchLoadReferenceRate() {
  yield takeEvery(ActionTypes.LOAD_REFERENCE_RATE_REQUEST, loadReferenceRate)
}

function* loadReferenceRate(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const currency: string = proposal.loan!.currency
  const asOf: string = proposal.hedge!.pricingDate

  try {
    const result = yield call(api.calculateSummary, proposal.id, asOf, currency)
    let summaryList: ScenarioSummary[] = result
    let refRate: ReferenceRate = { pay: undefined, receive: undefined }

    if (summaryList.length > 0) {
      const riskFactors = summaryList[0].scenario.riskFactors
      if (riskFactors[0])
        refRate.pay = riskFactors[0]!.initial
      if (riskFactors[1])
        refRate.receive = riskFactors[1]!.initial
    }

    yield put(loadReferenceRateSuccess(refRate))
  } catch (error) {
    console.log(error)
    yield put(loadReferenceRateFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(loadReferenceRateRequest(proposal))
    }
  }
}
