import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import { ScenarioCashflow } from "../../types"
import { CashflowInput } from './../../types'
import {
  calculateCashflowSuccess,
  calculateCashflowRequest,
  calculateCashflowFailure
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchCalculateCashflow() {
  yield takeEvery(ActionTypes.CALCULATE_CASHFLOW_REQUEST, calculateCashflow)
}

function* calculateCashflow(action: BaseAction) {
  const cashflowInput = action.payload as CashflowInput
  try {
    const result = yield call(api.calculateCashFlow, cashflowInput)
    const cashFlows: ScenarioCashflow[] = result

    yield put(calculateCashflowSuccess(cashFlows))
  } catch (error) {
    console.log(error)
    yield put(calculateCashflowFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(calculateCashflowRequest(cashflowInput))
    }
  }
}
