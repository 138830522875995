import { HedgeCard } from '../hedge-card'
import { Account } from "../../store/quickscan"
import { ax, createHeader, toCamelCase, toSnakeCase } from "./util"
import { randomId } from '../util'

export async function createAccount(account: Account): Promise<Account> {
  const json = await ax.post(`/accounts/`,
    toSnakeCase(account), createHeader())
  console.assert(json.status === 201)
  return toCamelCase(json.data)
}

export async function loadAccount(accountId: number): Promise<Account> {
  const json = await ax.get(`/accounts/${accountId}/`, createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function updateAccount(account: Account): Promise<Account> {
  const json = await ax.patch(`/accounts/${account.id}/`,
    toSnakeCase(account), createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function calcCards(account: Account): Promise<HedgeCard[]> {
  const json = await ax.post(`/accounts/calc_cards/`,
    toSnakeCase(account), createHeader())
  console.assert(json.status === 200)
  const cards = toCamelCase(json.data)
  cards.forEach((card: HedgeCard) => {
    card.key = randomId(8)
  })
  return cards
}

export async function getCard(cardId: number): Promise<HedgeCard> {
  const json = await ax.get(`/cards/${cardId}/`, createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function saveCard(card: HedgeCard): Promise<HedgeCard> {
  const json = await ax.post(`/cards/`, toSnakeCase(card), createHeader())
  console.assert(json.status === 201)
  return toCamelCase(json.data)
}
