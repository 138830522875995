import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/client'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { updateClientSuccess, updateClientFailure, updateClientRequest } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchUpdateClient() {
  yield takeEvery(ActionTypes.UPDATE_CLIENT_REQUEST, updateClient)
}

function* updateClient(action: BaseAction) {
  const { client } = action.payload
  const { callback } = action.meta

  if (!client.code) {
    yield put(updateClientFailure(`Client code is required.`))
    return
  }

  try {
    const updated = yield call(api.updateClient, client)
    yield put(updateClientSuccess(updated))

    if (callback) {
      callback()
    }
  } catch (error) {
    const responseCode = error.response.status
    let msg = 'Update client is failure.'
    if (responseCode === 400) {
      msg = `${error.response.data.code[0]}`
    } else if (responseCode === 503) {
      msg = 'Cannot connect to internet.'
    } else if (responseCode === 401) {
      msg = 'Unauthorized access.'
    }
    console.error(error)
    yield put(updateClientFailure(msg))
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateClientRequest(client, callback))
    }
  }

}
