import { call, put, takeEvery } from "@redux-saga/core/effects"

import { ActionTypes } from '../action-types'
import * as api from '../../../services/api/auth'
import * as jwt from '../../../services/auth'
import { BaseAction } from "../../../services/action"
import {
  refreshTokenSuccess, statusLoggingIn, statusLoggedIn,
  statusLoggedOut, refreshTokenFailure, deleteToken
} from "../actions"
import { loadUserProfileRequest } from "../actions/user-profile"

export function* watchRefreshToken() {
  yield takeEvery(ActionTypes.REFRESH_TOKEN_REQUEST, refreshToken)
}

/**
 * Tries to call the server to refresh the token. If succeeds,
 * then move user to logged-in status. Otherwise, treat as if
 * user has clicked 'log out', i.e. delete the token so that
 * we don't try to refresh again.
 */
function* refreshToken(action: BaseAction) {
  yield put(statusLoggingIn())
  const oldToken = action.payload.token as string
  try {
    const newToken = yield call(api.refreshToken, oldToken)
    const payload = jwt.decode(newToken)
    jwt.saveToken(newToken)
    yield put(refreshTokenSuccess(newToken, payload))
    yield put(statusLoggedIn())

    // Load the user profile
    yield put(loadUserProfileRequest())
  }
  catch (error) {
    // Log the failure
    yield put(refreshTokenFailure(error))

    // Try to verify the token. Might give us more insight into the failure.
    try {
      const verify = yield call(api.verifyToken, oldToken)
      console.log('Does server think token is valid?', verify ? 'Yes' : 'No')
    }
    catch (error) {
      console.log('Error trying to verify token:', error)
    }

    // Delete the token and hope App.tsx will try again
    yield put(deleteToken())

    // Change status back to logged out so that App.tsx will give login page
    yield put(statusLoggedOut())
  }
}
