import * as React from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import history from './history'

const ClientList = React.lazy(() => import('../pages/client-list'))
const ClientDetail = React.lazy(() => import('../pages/client-detail'))
const ClientNew = React.lazy(() => import('../pages/client-detail/containers/client-new'))

const Header = React.lazy(() => import('../pages/header'))
const ScheduleList = React.lazy(() => import('../pages/schedule-list'))
const ScheduleDetail = React.lazy(() => import('../pages/schedule-detail'))

const ProposalList = React.lazy(() => import('../pages/proposal-list'))
const ProposalDetail = React.lazy(() => import('../pages/proposal-detail'))

const ChangePassword = React.lazy(() => import('../pages/auth/change-password'))
const Profile = React.lazy(() => import('../pages/profile'))
const LogoutSuccess = React.lazy(() => import('../pages/auth/logout-success'))

function Main() {

  return (
    <Router history={history}>
      <React.Fragment>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Header />
        </React.Suspense>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/client/:id" render={() => <ClientDetail />} />
            <Route path="/client" render={() => <ClientList />} />
            <Route path="/client-new" render={() => <ClientNew />} />

            <Route path="/client-schedule-list/:clientId" exact render={() => <ScheduleList />} />
            <Route path="/client-schedule-detail/:scheduleId" exact render={() => <ScheduleDetail />} />

            <Route path="/schedule-list" exact render={() => <ScheduleList />} />
            <Route path="/schedule-detail/:scheduleId" exact render={() => <ScheduleDetail />} />

            <Route path="/proposal-list/:clientId?" exact render={() => <ProposalList />} />
            <Route path="/proposal-detail/:id" render={() => <ProposalDetail />} />

            <Route path="/account/change-password" exact render={() => <ChangePassword />} />
            <Route path="/account/profile" exact render={() => <Profile />} />
            <Route path="/auth/logout-success" exact render={() => <LogoutSuccess />} />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    </Router>
  )
}

export default Main