import { ax, createHeader, toCamelCase, toSnakeCase, handleError } from './util'
import { Proposal, PriceDetail } from '../../store/proposal'

export async function createProposal(clientId: number) {
  const data = toSnakeCase({ clientId })
  const json = await ax.post(`/proposals/`, data, createHeader())
  console.assert(json.status === 201)
  return json.data
}

export async function getProposal(id: number): Promise<Proposal> {
  const json = await ax.get(`/proposals/${id}/`, createHeader())
  console.assert(json.status === 200)
  return updateCardClient(toCamelCase(json.data))
}

/** Return the proposal with its price updated if necessary. */
export async function recalcProposalPrice(id: number): Promise<Proposal> {
  const json = await ax.post(`/proposals/${id}/proposal_with_updated_price/`,
    {}, createHeader())
  console.assert(json.status === 200)
  return updateCardClient(toCamelCase(json.data))
}

export async function getProposals(): Promise<Proposal[]> {
  const json = await ax.get(`/proposals/`, createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function updateProposal(
  id: number | undefined,
  activeCardId: number | undefined,
  loanId: number | undefined,
  hedgeId: number | undefined) {

  const data = {
    activeCardId,
    loanId,
    hedgeId,
  }

  const json = await ax.patch(`/proposals/${id}/`, toSnakeCase(data),
    createHeader())
  console.assert(json.status === 200)

  const proposal = toCamelCase(json.data) as Proposal
  return updateCardClient(proposal)
}

export async function deleteProposal(proposalId: number) {
  const response = await ax.delete(`/proposals/${proposalId}/`, createHeader())
  console.assert(response.status === 204)
}

// On the server, HedgeCard does not store its Client. So we need
// to restore it here.
function updateCardClient(proposal: Proposal): Proposal {
  if (proposal.activeCard)
    proposal.activeCard.client = proposal.client.id

  return proposal
}

export async function calculateMargin(
  proposalId: number,
  price: number): Promise<number> {
  const data = {
    asOf: '2019-03-01',
    receive_rate: price,
  }
  try {
    const json = await ax.post(`/proposals/${proposalId}/calc_margin/`,
      toSnakeCase(data),
      createHeader())
    return toCamelCase(json.data)
  } catch (error) {
    throw Error(handleError(error))
  }

}

export async function calculatePrice(
  proposalId: number,
  margin: number): Promise<number> {
  const data = {
    asOf: '2019-03-01',
    margin,
  }
  const json = await ax.post(`/proposals/${proposalId}/calc_price/`,
    toSnakeCase(data),
    createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function calculatePriceDetail(
  proposalId: number,
  pricingDate: string | undefined,
  margin: number | undefined,
  finalPrice: number | undefined): Promise<PriceDetail> {
  const data = {
    pricingDate,
    margin,
    finalPrice,
  }
  const json = await ax.post(`/proposals/${proposalId}/calc_price_detail/`,
    toSnakeCase(data),
    createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

/** Update the proposal with the given price details. Returns the new proposal. */
export async function updateProposalPrice(
  proposalId: number,
  pricingDate: string | undefined,
  tradeDate: string | undefined,
  margin: number | undefined,
  finalPrice: number | undefined): Promise<Proposal> {

  const data = {
    pricingDate,
    tradeDate,
    margin,
    finalPrice,
  }

  const json = await ax.post(`/proposals/${proposalId}/update_price/`,
    toSnakeCase(data), createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function loadActivityLogs(proposalId: number) {
  const json = await ax.get(`/proposals/${proposalId}/activity_list/`,
    createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function loadSummaryLists(proposalId: number) {
  const json = await ax.get(`/proposals/${proposalId}/report_list/`,
    createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}

export async function loadPastProposalPDF(id: number) {
  const json = await ax.get(
    `/proposal_summary_report/${id}/`,
    {
      responseType: 'blob',
      headers: createHeader().headers
    }
  )
  console.assert(json.status === 200)
  var url = URL.createObjectURL(json.data)
  return url
}
