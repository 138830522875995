
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { AuthState, reducer as authReducer } from './auth'
import loan, { LoanState } from './loan'
import client, { ClientState } from './client'
import proposal, { ProposalState } from './proposal'
import quickscan, { QuickscanState } from './quickscan'
import scenario, { ScenarioState } from './scenario'

export interface AppState {
  auth: AuthState,
  client: ClientState,
  loan: LoanState,
  proposal: ProposalState,
  quickscan: QuickscanState,
  scenario: ScenarioState,
  form: any,
}

export const rootReducer = combineReducers<AppState>({
  auth: authReducer,
  client,
  loan,
  proposal,
  quickscan,
  scenario,
  form: formReducer,
})
