import React from 'react'
import ReactDOM from 'react-dom'

import Root from './components/root'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/configure-store'

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/css/custom.scss'
import './styles/css/animate.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
const store = configureStore()

ReactDOM.render(
  <Root store={store} />,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
