export enum ActionTypes {
  UPDATE_LOAN_LIST = "@@loan-list/UPDATE_LOAN_LIST",
  RESET_LOAN_LIST = "@@loan-list/RESET_LOAN_LIST",
  UPDATE_CLIENT = "@@loan-list/UPDATE_CLIENT",
  RESET_BUSY = "@@loan-list/RESET_BUSY",
  BEGIN_BUSY = "@@loan-list/BEGIN_BUSY",
  END_BUSY = "@@loan-list/END_BUSY",
  DELETE_LOAN_LIST_FAILURE = "@@loan-list/DELETE_LOAN_LIST_FAILURE",
  RESET_LOAN_LIST_ERROR_MESSAGE = "@@loan-list/RESET_LOAN_LIST_ERROR_MESSAGE",
}
