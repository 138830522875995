import { createStore, applyMiddleware, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { rootReducer } from '.'
import { AppState } from '.'
import rootSaga from './sagas'

let rdx: any
if (process.env.NODE_ENV === 'development') {
  rdx = require('redux-devtools-extension')
}

function maskPassword(action: any) {
  if (action.type === '@@auth/LOGIN_REQUEST') {
    const { payload } = action
    return {
      ...action,
      payload: {
        ...payload,
        password: '<redacted>',
      },
    }
  }

  return action;
}

const logger = createLogger({
  collapsed: true,
  actionTransformer: maskPassword,
})

const sagaMiddleware = createSagaMiddleware()

const middleware = [
  thunk,
  logger,
  sagaMiddleware,
]

function configureStore(): Store<any> {
  const persistedState = {}
  let store: Store<any> = createStore<AppState, any, any, any>(
    rootReducer,
    persistedState,
    rdx ? rdx.composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware),
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
