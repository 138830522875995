import moment from 'moment';

export const isoDate = 'YYYY-MM-DD'

export const inputDateFormats = [
  'YYYY-MM-DD',
  'DD-MMM-YYYY',
  'DD/MM/YYYY',
  'D/M/YYYY',
]

export const acceptedDateFormat = [
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "DD-MMM-YY",
  "DD/MMM/YYYY",
  "DD-MMM-YYYY",
  "YYYY-MM-DD",
  "YYYY/MM/DD",
  "YYYY-MMM-DD",
  "YYYY/MMM/DD",
]

// Number of days between 2 dates
export const numDays = (date1: string, date2: string) => {
  const d1 = moment(date1, inputDateFormats)
  const d2 = moment(date2, inputDateFormats)
  return d1.isValid() && d2.isValid() ? d2.diff(d1, 'days') : 0 // date2 - date1
}

export const addDays = (date: string, n: number, format: string) => {
  return moment(date, inputDateFormats).add(n, 'days').format(format)
}

// Approximate number of years between 2 dates (365 days a year)
export const numYears = (date1: string, date2: string) => {
  const d1 = moment(date1, inputDateFormats)
  const d2 = moment(date2, inputDateFormats)
  return d2.diff(d1, 'years', true)
}

// Number of years and months from 1st date to 2nd date
export function numYearsMonths(date1: string, date2: string): [number, number] {
  const d1 = moment(date1, inputDateFormats)
  const d2 = moment(date2, inputDateFormats)
  const months = d2.diff(d1, 'months')
  const y = Math.floor(months / 12)
  const m = months - y * 12
  return [y, m]
}

export function convertToFormat(
  dateString: string,
  fromFormat: string = inputDateFormats[0],
  toFormat: string = inputDateFormats[2]
) {
  return moment(dateString, fromFormat).format(toFormat)
}

export function getFirstOfNextMonth(): Date {
  const today = new Date()
  let y = today.getFullYear()
  const m = today.getMonth()
  let m2 = m + 1
  if (m2 > 12) {
    m2 = 1
    y += 1
  }
  return new Date(y, m2, 1)
}

export function add3Months(date: Date): Date {
  return addMonths(date, 3)
}

export function addMonths(date: Date, numMonths: number): Date {
  return moment(date)
    .add(numMonths, 'months')
    .toDate()
}
