
import { watchCalculateCards } from './calculate-cards'
import { watchLoadAccount } from './load-account'
import { watchUpdateAccount } from './update-account'

export default [
  watchCalculateCards,
  watchLoadAccount,
  watchUpdateAccount,
]
