import { action } from "typesafe-actions"

import ActionTypes from './action-types'
import { ScenarioSummary, ScenarioCashflow } from "../types"
import { CashflowInput, CustomRiskFactor } from './../types'

export function calculateSummaryRequest(
  proposalId: number,
  asOf: string,
  currency: string
) {
  return action(ActionTypes.CALCULATE_SUMMARY_REQUEST, {
    proposalId,
    asOf,
    currency,
  })
}

export function calculateSummarySuccess(summaryList: ScenarioSummary[]) {
  return action(ActionTypes.CALCULATE_SUMMARY_SUCCESS, { summaryList })
}

export function calculateSummaryFailure() {
  return action(ActionTypes.CALCULATE_SUMMARY_FAILURE)
}

export function calculateCashflowRequest(cashflowInput: CashflowInput) {
  return action(ActionTypes.CALCULATE_CASHFLOW_REQUEST, cashflowInput)
}

export function calculateCashflowSuccess(cashflows: ScenarioCashflow[]) {
  return action(ActionTypes.CALCULATE_CASHFLOW_SUCCESS, { cashFlows: cashflows })
}

export function calculateCashflowFailure() {
  return action(ActionTypes.CALCULATE_CASHFLOW_FAILURE)
}

export function loadCustomRiskFactorRequest(proposalId: number) {
  return action(ActionTypes.LOAD_CUSTOM_RISK_FACTOR_REQUEST, { proposalId })
}

export function loadCustomRiskFactorSuccess(customRiskFactors: CustomRiskFactor[]) {
  return action(ActionTypes.LOAD_CUSTOM_RISK_FACTOR_SUCCESS, { customRiskFactors })
}

export function loadCustomRiskFactorFailure() {
  return action(ActionTypes.LOAD_CUSTOM_RISK_FACTOR_FAILURE)
}

export function createCustomRiskFactorRequest(
  proposalId: number,
  customRiskFactor: CustomRiskFactor,
  currency: string
) {
  return action(ActionTypes.CREATE_CUSTOM_RISK_FACTOR_REQUEST,
    {
      proposalId,
      customRiskFactor,
      currency
    }
  )
}

export function createCustomRiskFactorSuccess(customRiskFactor: CustomRiskFactor) {
  return action(ActionTypes.CREATE_CUSTOM_RISK_FACTOR_SUCCESS, { customRiskFactor })
}

export function createCustomRiskFactorFailure() {
  return action(ActionTypes.CREATE_CUSTOM_RISK_FACTOR_FAILURE)
}

export function updateCustomRiskFactorRequest(
  proposalId: number,
  customRiskFactor: CustomRiskFactor,
  currency: string) {
  return action(ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_REQUEST,
    {
      proposalId,
      customRiskFactor,
      currency
    }
  )
}

export function updateCustomRiskFactorSuccess(customRiskFactor: CustomRiskFactor) {
  return action(ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_SUCCESS, { customRiskFactor })
}

export function updateCustomRiskFactorFailure() {
  return action(ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_FAILURE)
}

export function deleteCustomRiskFactorRequest(
  proposalId: number,
  customRiskFactorId: number,
) {
  return action(ActionTypes.DELETE_CUSTOM_RISK_FACTOR_REQUEST,
    {
      proposalId,
      customRiskFactorId,
    }
  )
}

export function deleteCustomRiskFactorSuccess(customRiskFactorId: number) {
  return action(ActionTypes.DELETE_CUSTOM_RISK_FACTOR_SUCCESS, { customRiskFactorId })
}

export function deleteCustomRiskFactorFailure() {
  return action(ActionTypes.DELETE_CUSTOM_RISK_FACTOR_FAILURE)
}
