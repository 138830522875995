import { action } from "typesafe-actions"

import ActionTypes, { Proposal } from './types'
import { Client } from "../../client"

export function loadProposalsRequest() {
  return action(ActionTypes.LOAD_PROPOSALS_REQUEST)
}

export function loadProposalsSuccess(proposals: Proposal[]) {
  return action(ActionTypes.LOAD_PROPOSALS_SUCCESS, proposals)
}

export function loadProposalsFailure() {
  return action(ActionTypes.LOAD_PROPOSALS_FAILURE)
}

export function loadActivityLogRequest(proposalId: number, callback: (item: any) => void) {
  return action(ActionTypes.LOAD_ACTIVITY_LOG_REQUEST, { proposalId }, { callback })
}

export function loadActivityLogSuccess() {
  return action(ActionTypes.LOAD_ACTIVITY_LOG_SUCCESS)
}

export function loadActivityLogFailure() {
  return action(ActionTypes.LOAD_ACTIVITY_LOG_FAILURE)
}

export function loadSummaryListRequest(proposalId: number, callback: (item: any) => void) {
  return action(ActionTypes.LOAD_SUMMARY_LIST_REQUEST, { proposalId }, { callback })
}

export function loadSummaryListSuccess() {
  return action(ActionTypes.LOAD_SUMMARY_LIST_SUCCESS)
}

export function loadSummaryListFailure() {
  return action(ActionTypes.LOAD_SUMMARY_LIST_FAILURE)
}

export function generatePastProposalPDFRequest(id: number, callback: (item: any) => void) {
  return action(ActionTypes.GENERATE_PAST_PROPOSAL_PDF_REQUEST, { id }, { callback })
}

export function generatePastProposalPDFSuccess() {
  return action(ActionTypes.GENERATE_PAST_PROPOSAL_PDF_SUCCESS)
}

export function generatePastProposalPDFFailure() {
  return action(ActionTypes.GENERATE_PAST_PROPOSAL_PDF_FAILURE)
}

export function loadClientRequest(clientId: number) {
  return action(ActionTypes.LOAD_CLIENT_REQUEST, { clientId })
}

export function loadClientSuccess(client: Client) {
  return action(ActionTypes.LOAD_CLIENT_SUCCESS, client)
}

export function loadClientFailure() {
  return action(ActionTypes.LOAD_CLIENT_FAILURE)
}
