import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import {
  deleteCustomRiskFactorFailure,
  deleteCustomRiskFactorSuccess,
  deleteCustomRiskFactorRequest
} from '../actions'
import { toSnakeCase } from '../../../../services/api/util'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchDeleteCustomRiskFactor() {
  yield takeEvery(ActionTypes.DELETE_CUSTOM_RISK_FACTOR_REQUEST, deleteCustomRiskFactor)
}

function* deleteCustomRiskFactor(action: BaseAction) {
  const { proposalId, customRiskFactorId } = action.payload
  try {
    const data = toSnakeCase({ id: customRiskFactorId })
    yield call(api.deleteCustomRiskFactor, proposalId, data)
    yield put(deleteCustomRiskFactorSuccess(customRiskFactorId))

  } catch (error) {
    console.log(error)
    yield put(deleteCustomRiskFactorFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        deleteCustomRiskFactorRequest(proposalId, customRiskFactorId)
      )
    }
  }
}
