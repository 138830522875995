import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as hedgeApi from '../../../../services/api/hedge'
import * as proposalApi from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import {
  updatePreConfiguredLoanSuccess,
  updatePreConfiguredLoanRequest,
  updatePreConfiguredLoanFailed
} from "../actions"
import { Loan } from "../../../loan/types"
import { Proposal } from "../.."
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


export function* watchUpdatePreconfiguredLoan() {
  yield takeEvery(ActionTypes.UPDATE_PRE_CONFIGURED_LOAN_REQUEST, updateLoan)
}

/** 
 * Let proposal use a preconfigured loan.
 * 
 * Let the server create a enw hedge to match the loan,
 * and update server with new loan and hedge.
 * 
 * Returns the newly-updated proposal.
 */
function* updateLoan(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const loan: Loan = action.payload.loan
  const callback: any = action.payload.callback
  const card = proposal.activeCard!

  try {
    // Create new hedge on server that fully hedges loan
    const hedge = yield call(
      hedgeApi.createHedgeFromLoan,
      loan.id,
      card.id
    )

    const newProposal = yield call(
      proposalApi.updateProposal,
      proposal.id,
      card.id,
      loan.id,
      hedge.id,
    )

    yield put(updatePreConfiguredLoanSuccess(newProposal))
    if (callback)
      callback()
  } catch (error) {
    console.log(error)
    yield put(updatePreConfiguredLoanFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updatePreConfiguredLoanRequest(proposal, loan))
    }
  }
}
