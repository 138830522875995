import { drop, last, range, uniqBy, dropRight, zip, every } from 'lodash'
import moment from 'moment'
import numbro from 'numbro'

import {
  Loan,
  Period,
  PeriodBundle,
  InterestRate,
  FloatingRate,
  FixedRate
} from '../store/loan/types'
import { inputDateFormats, isoDate, numDays, numYears } from './date'
import { getCurveChoices, randomNumeric } from './util'
import { HedgeCard } from './hedge-card'
import { defaultFullNotional } from './config'


const percent = { output: 'percent', mantissa: 2 }

export function startingNotional(loan: Loan) {
  if (isNaN(loan.schedule[0].notional))
    return 0
  return loan.schedule[0].notional
}

export function startDate(loan: Loan): string {
  return loan.schedule[0].start
}

export function endDate(loan: Loan): string {
  const filtered = loan.schedule.filter(p => p.end !== null)
  return last(filtered)!.end
}

/**
 * Return the changes in notional values for each period, from the
 * previous period. In a regular amortising structure, all the
 * notional changes are negative. The final value is equal to the
 * (negative of) notional of the last period.
 */
export function notionalChanges(schedule: Period[]): number[] {
  const a1 = dropRight(schedule, 1).map(p => p.notional)
  const a2 = drop(schedule, 1).map(p => p.notional)
  const changes = zip(a1, a2).map(x => {
    const [x1, x2] = x
    return x2! - x1!
  })
  changes.push(-schedule[schedule.length - 1].notional)
  return changes
}

function isAmortising(schedule: Period[]): boolean {
  return every(notionalChanges(schedule).map(chg => chg <= 0))
}

function isAccreting(schedule: Period[]): boolean {
  return every(dropRight(notionalChanges(schedule).map(chg => chg >= 0)))
}

function isBullet(schedule: Period[]): boolean {
  return uniqBy(schedule, period => period.notional).length === 1
}

/** Returns whether the schedule is 'Bullet', 'Amortising' or 'Others' */
export function amountStructure(schedule: Period[]): string {
  if (isBullet(schedule))
    return 'Bullet'
  else if (isAmortising(schedule))
    return 'Amortising'
  else if (isAccreting(schedule))
    return 'Accreting'
  return 'Roller Coaster'
}

// Make sure that each start date is the previous period's end.
export function updateStartDates(periods: Period[] | PeriodBundle[]) {
  if (!periods || periods!.length === 0)
    return periods

  let prevEnd = periods[0].end
  drop(periods as any[], 1).forEach(p => {
    p.start = moment(prevEnd, inputDateFormats, true).format(isoDate)
    prevEnd = p.end
  })
}

// How long is the schedule for?
export function years(loan: Loan) {
  return numYears(startDate(loan), endDate(loan))
}

export function generateRateText(rate: InterestRate, showSpread: boolean = true) {
  let text = []

  if (rate.rateType === 'fixed') {
    text.push(`Fixed ${rate.currency}`)
  }
  else {
    text.push(`${curveName(rate)}`)
  }

  if (showSpread) {
    if (rate.rateType !== 'fixed')
      text.push(`+${numbro(rate.spread).format(percent)}`)
    else
      text.push(` ${numbro(rate.spread).format(percent)}`)
  }

  return text.join(' ')
}

/**
 * If rate is a fixed rate, return null. Else return words like:
 * '3m LIBOR'
 */
export function curveName(rate: InterestRate) {
  if (rate.rateType === 'fixed') return null

  return `${rate.tenor}m ${rate.index.toUpperCase()}`
}

type LoanType = 'loan_fixed' | 'loan_float'

export function createDefaultRate(ccy: string, pos: LoanType): InterestRate {
  if (pos === 'loan_fixed') {
    return {
      rateType: 'fixed',
      currency: ccy,
      spread: 0.05,
    }
  }
  return {
    rateType: 'float',
    index: getCurveChoices(ccy)[0],
    tenor: 3,
    spread: 0.04,
  }
}

export function createQuickSchedule(
  clientId: number,
  currency: string
): Loan {
  const id = 0
  const name = `Loan-${randomNumeric(4)}`

  const numPeriods = 5 * 4 // 5 years, quarterly

  const startOfPeriod = getFirstOfNextMonth()
  let date = startOfPeriod
  const fullNotional = defaultFullNotional
  let notional = fullNotional
  const repayment = fullNotional / numPeriods
  const schedule = Array<Period>()

  // eslint-disable-next-line
  for (const _ of range(0, numPeriods)) {
    const endOfPeriod = add3Months(date)
    const start = moment(date).format(isoDate)
    const end = moment(endOfPeriod).format(isoDate)
    const n = numDays(start, end)
    const period = {
      start,
      end,
      notional,
      numDays: n,
      repayment,
    }
    schedule.push(period)

    date = endOfPeriod
    notional -= repayment
  }

  return {
    id,
    clientId,
    currency,
    name,
    schedule,
    interestRate: {
      rateType: 'fixed',
      currency,
      spread: 0.05,
    },
    kind: 3,
    created: 0,
    updated: 0,
  }
}

/** Create a 'standard' Loan based on the given hedge card */
export function createStandardLoan(card: HedgeCard): Loan {
  const id = 0
  const clientId = card.client
  const name = `Liability-${randomNumeric(4)}`
  const currency = card.position.ccy

  const interestRate = createStandardInterestRate(
    currency,
    card.position.pos === 'loan_fixed' ? 'fixed' : 'float'
  )

  const numPeriods = 5 * 4 // 5 years, quarterly
  const startOfPeriod = getFirstOfNextMonth()
  let date = startOfPeriod
  const fullNotional = defaultFullNotional
  let notional = fullNotional
  const repayment = fullNotional / numPeriods

  const schedule = Array<Period>()
  range(0, numPeriods).forEach(() => {
    const endOfPeriod = add3Months(date)
    const start = moment(date).format(isoDate)
    const end = moment(endOfPeriod).format(isoDate)
    const n = numDays(start, end)
    const period = {
      start,
      end,
      notional,
      numDays: n,
      repayment,
    }
    schedule.push(period)

    date = endOfPeriod
    notional -= repayment
  })

  return {
    id,
    clientId,
    currency,
    name,
    schedule,
    interestRate,
    kind: 1,
    created: 0,
    updated: 0,
  }
}

export function getFirstOfNextMonth(): Date {
  const today = new Date()
  let y = today.getFullYear()
  const m = today.getMonth()
  let m2 = m + 1
  if (m2 > 12) {
    m2 = 1
    y += 1
  }
  return new Date(y, m2, 1)
}

function createStandardInterestRate(
  currency: string,
  rateType: 'fixed' | 'float'): InterestRate {

  if (rateType === 'fixed') {
    return {
      rateType,
      spread: 0.05,
      currency,
    }
  }
  else {
    return {
      rateType,
      spread: 0.04,
      index: getCurveChoices(currency)[0],
      tenor: 3,
    }
  }
}

// export function getFirstOfNextMonthServerFormat(): string {
// 	let nDate = moment(getFirstOfNextMonth()).format('YYYY-MM-DD')
// 	return nDate
// }

function add3Months(date: Date): Date {
  return addMonths(date, 3)
}

function addMonths(date: Date, numMonths: number): Date {
  return moment(date)
    .add(numMonths, 'months')
    .toDate()
}

export function formatDate(
  dateString: string,
  format: 'slash' | 'read' | 'server-std'
) {
  if (!dateString) return ''
  const formatObj = {
    slash: 'DD/MM/YYYY',
    read: 'dd MMM YYYY',
    'server-std': 'YYYY-MM-DD',
  }
  const newDateF = dateString
    ? moment(dateString, 'YYYY-MM-DD').format(formatObj[format])
    : ''
  return newDateF
}

export function LoanKindText(loan: Loan) {
  switch (loan.kind) {
    case 1:
      return 'standard'
    case 2:
      return 'user customised'
    case 3:
      return 'pre-configured'
    default:
      return ''
  }
}

export function updateInterestIndex(loan: Loan): InterestRate {
  let interestRate = { ...loan.interestRate }
  if (loan.interestRate.rateType === 'float') {
    interestRate = { ...loan.interestRate } as FloatingRate
    const curveChoices = getCurveChoices(loan.currency)
    interestRate.index = (curveChoices.length > 0) ? curveChoices[0] : ''
  } else if (loan.interestRate.rateType === 'fixed') {
    interestRate = { ...loan.interestRate } as FixedRate
    interestRate.currency = loan.currency
  }

  return interestRate
}
