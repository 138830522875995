import { watchCalculateSummary } from './calculate-summary'
import { watchCalculateCashflow } from './calculate-cashflow'
import { watchLoadCustomRiskFactor } from './load-custom-risk-factor'
import { watchCreateCustomRiskFactor } from './create-custom-risk-factor'
import { watchUpdateCustomRiskFactor } from './update-custom-risk-factor'
import { watchDeleteCustomRiskFactor } from './delete-custom-risk-factor'

export default [
  watchCalculateSummary,
  watchCalculateCashflow,
  watchLoadCustomRiskFactor,
  watchCreateCustomRiskFactor,
  watchUpdateCustomRiskFactor,
  watchDeleteCustomRiskFactor,
]
