import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as loanApi from '../../../../services/api/loan'
import * as hedgeApi from '../../../../services/api/hedge'
import * as proposalApi from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import {
  updateLoanSuccess,
  updateLoanRequest,
  updateLoanFaield
} from "../actions"
import { Loan } from "../../../loan/types"
import { Proposal } from "../.."
import { HedgeCard } from "../../../../services/hedge-card"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchUpdateLoan() {
  yield takeEvery(ActionTypes.UPDATE_LOAN_REQUEST, updateLoan)
}

/** 
 * Update the proposal's loan on server
 * 
 * Then let server create a new hedge to match the loan,
 * and update the server with the new loan and hedge.
 * 
 * Returns the newly-updated proposal.
 */
function* updateLoan(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const loan: Loan = action.payload.loan
  let card: HedgeCard | undefined = action.payload.card

  if (!card)
    card = proposal.activeCard!

  // Save the loan to server

  try {
    const newLoan: Loan = yield call(loanApi.createLoan, loan)

    // Create a new hedge on server that fully hedges the loan.
    const hedge = yield call(
      hedgeApi.createHedgeFromLoan,
      newLoan.id,
      card.id
    )

    let newProposal: Proposal = yield call(
      proposalApi.updateProposal,
      proposal.id,
      card.id,
      newLoan.id,
      hedge.id
    )

    yield put(updateLoanSuccess(newProposal))
  } catch (error) {
    console.log(error)
    yield put(updateLoanFaield())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateLoanRequest(proposal, loan, card))
    }
  }
}
