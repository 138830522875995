import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/client'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { getClientListSuccess, getClientListRequest, getClientListFailure } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchGetClientList() {
  yield takeEvery(ActionTypes.GET_CLIENT_LIST_REQUEST, getClientList)
}

function* getClientList(action: BaseAction) {
  try {
    const clients = yield call(api.getClientList)
    yield put(getClientListSuccess(clients))
  }
  catch (error) {
    console.error(error)
    yield put(getClientListFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(getClientListRequest())
    }
  }
}
