import { action } from 'typesafe-actions'

import { ActionTypes } from '../action-types'
import { UserProfile } from '../types'


export function loadUserProfileRequest() {
  return action(ActionTypes.LOAD_USER_PROFILE_REQUEST)
}

export function loadUserProfileSuccess(me: UserProfile) {
  return action(ActionTypes.LOAD_USER_PROFILE_SUCCESS, { me })
}
