import axios from 'axios'
import { snakeCase, isObject, isArray, reduce, camelCase } from 'lodash'
import { isAzureAD } from './azure-ad'

// This function is to help development, so that we
// can (1) 'manage.py runserver' serving localhost:8000/api/
// while (2) having another console doing npm start (which serves
// react front-end on localhost:3000. This function will take
// the localhost:3000 and translate that to localhost:8000/api/
// for api requests)
export const APIURL = function () {
  let origin = window.location.origin
  if (origin.endsWith(':3000')) {
    // We are probably in dev; use port :8000 for api
    origin = origin.replace(':3000', ':8000')
  }
  return origin + '/api'
}()

// Create an instance of axios pre-configured with some settings
export const ax = axios.create({
  baseURL: APIURL,
})

/**
 * Create a header containing the JWT token (used by axios)
 */
export function createHeader() {
  const prefix = isAzureAD() ? 'Bearer' : 'JWT'
  return {
    headers: {
      'Authorization': `${prefix} ${localStorage.getItem('jwtToken')}`
    }
  }
}

/**
 * Create a header containing the JWT token (used by http fetch)
 */
export function getAuthHeader() {
  const jwtToken = localStorage.getItem('jwtToken')
  return {
    'Accept': 'application/json',
    'Authorization': 'JWT ' + jwtToken,
    'Content-Type': 'application/json',
  }
}

export function handleError(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
    return (`Error ${error.response.status} ${JSON.stringify(error.response.data)}`)
  }
  else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // THS: There isn't any useful information in error.request :-(
    console.error(error.request)
    return ('Network Error: no response from server')
  }
  else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error', error.message)
    return (`Something strange happend: ${error.message}`)
  }
}

/**
 * Converts an object and recursively * converts all the 
 * js-style camelCase keys to snake_case. 
 * e.g. {helloWorld: {...}} ==> {hello_world: {...}}
 * 
 * https://stackoverflow.com/questions/4647817/javascript-object-rename-key
 */
export function toSnakeCase(obj: any): any {
  if (!isObject(obj)) {
    return obj;
  }
  else if (isArray(obj)) {
    return obj.map(v => toSnakeCase(v));
  }
  else {
    return reduce(obj, (r, v, k) => {
      return {
        ...r,
        [snakeCase(k)]: toSnakeCase(v)
      };
    }, {});
  }
}

/**
 * Converts object keys from any case to camel case. Typically
 * to convert python's object dictionary to javascript's style
 */
export function toCamelCase(obj: any): any {
  if (!isObject(obj)) {
    return obj;
  }
  else if (isArray(obj)) {
    return obj.map(v => toCamelCase(v));
  }
  else {
    return reduce(obj, (r, v, k) => {
      return {
        ...r,
        [camelCase(k)]: toCamelCase(v)
      };
    }, {});
  }
}
