import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/client'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { getClientSuccess, getClientRequest, getClientFailure } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchGetClient() {
  yield takeEvery(ActionTypes.GET_CLIENT_REQUEST, getClient)
}

function* getClient(action: BaseAction) {
  const { clientId } = action.payload
  try {
    const client = yield call(api.getClient, clientId)
    yield put(getClientSuccess(client))
  }
  catch (error) {
    console.error(error)
    yield put(getClientFailure(clientId))
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(getClientRequest(clientId))
    }
  }
}
