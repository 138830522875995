import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { loadProposalsRequest } from "../../proposal-list/actions"
import { deleteProposalRequest, deleteProposalFailed, deleteProposalSuccess } from '../actions'
import { renewAzureTokenAndPut, isInvalidTokenError } from '../../../util'
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchDeleteProposal() {
  yield takeEvery(ActionTypes.DELETE_PROPOSAL_REQUEST, deleteProposal)
}

function* deleteProposal(action: BaseAction) {
  try {
    const proposalId = action.payload as number
    yield call(api.deleteProposal, proposalId)
    yield put(loadProposalsRequest())
    yield put(deleteProposalSuccess())
  } catch (error) {
    console.error(error)
    yield put(deleteProposalFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(deleteProposalRequest(action.payload))
    }
  }
}
