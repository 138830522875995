import { ax, createHeader, toCamelCase } from './util'
import { UserProfile } from '../../store/auth/types'

export async function requestToken(email: string, password: string) {
  const json = await ax.post(`/gettoken/`, { email, password })
  return json.data.token
}

export async function changePwd(currentPwd: string, newPwd: string) {
  await ax.post(`/auth/user/set_password/`, { current_password: currentPwd, new_password: newPwd }, createHeader())
  return true
}

export async function refreshToken(token: string) {
  try {
    const json = await ax.post(`/refreshtoken/`, { token })
    return json.data.token
  }
  catch (error) {
    // The token may be valid (not expired).
    // /verifytoken/ may even be true.
    // But JWT_REFRESH_EXPIRATION_DELTA might stop us from
    // refreshing the token. We won't know until we try,
    // like right now.
    if (error.response.status === 400) {
      const reason = JSON.stringify(error.response.data)
      console.log('refreshToken vailed. Reaons:', reason)
      throw error.response
    }
  }
}

export async function verifyToken(token: string) {
  const json = await ax.post(`/verifytoken/`, { token })
  if (json.status === 200) {
    // yes the token is good
    return true
  }
  else if (json.status === 400) {
    // nope the token is bad
    return false
  }
  else {
    // Not allowed to be here. Specs only allow status == 200 or 400
    console.error(json.data)
  }
}

export async function loadUserProfile(): Promise<UserProfile> {
  const json = await ax.get(`/auth/user/me/`, createHeader())
  console.assert(json.status === 200)
  return toCamelCase(json.data)
}
