import * as React from 'react'
import { LoginStatus } from '../../../../store/auth/types'
import { Formik } from 'formik'

import LoginPageFormInput from './login-page-form-input'
import { createValidateSchema } from './login-page-validation'
import { LoginType, LoginTouch } from './login-page-form-input'

interface InputProps {
  email: string
  onLogin: any
  error: string
  loginStatus: LoginStatus
}

type Props = InputProps

const initialLogin = { email: '', password: '' }
const initialLoginTouch = { email: false, password: false }

function LoginPage(props: Props) {

  const { error, loginStatus, email } = props

  const [loginState, setLoginState] = React.useState(initialLogin as LoginType)
  const [errorState, setErrorState] = React.useState(error)
  const [statusState] = React.useState(loginStatus)
  const [touchState, setTouchState] = React.useState(initialLoginTouch as LoginTouch)

  React.useEffect(() => {
    const newLoginData = { ...initialLogin, email }
    setLoginState(newLoginData)
  }, [email])

  function handleLogin() {
    if (loginState.email && loginState.password) {
      const email = loginState.email.trim()
      const password = loginState.password.trim()
      setErrorState('')
      props.onLogin(email, password)
    }
  }

  function handleOnChange(loginData: LoginType) {
    const newLoginData = (!loginData) ? initialLogin : loginData
    setLoginState({ ...newLoginData })
  }

  function handleTouchedInput(loginTouch: LoginTouch) {
    setTouchState({ ...loginTouch })
  }

  function handleOnClearError() {
    //setErrorState('')
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...loginState }}
        onSubmit={handleLogin}
        validationSchema={createValidateSchema()}
      >
        {fProps => {
          return (
            <>
              <LoginPageFormInput
                loginError={errorState}
                loginStatus={statusState}
                loginTouch={touchState}
                formikProps={fProps}
                onChange={handleOnChange}
                onTouch={handleTouchedInput}
                onClearError={handleOnClearError}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default LoginPage
