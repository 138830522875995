
export enum ActionTypes {
  // Quickscan
  RESET_QUICKSCAN = "@@quickscan/RESET_QUICKSCAN",
  SET_CARD_DISPLAY_MODE = "@@quickscan/SET_CARD_DISPLAY_MODE",
  SET_BUSY_COUNT = "@@quickscan/SET_BUSY_COUNT",
  SET_FINISH = "@@quickscan/SET_FINISH",
  // Cards
  CALCULATE_CARDS_REQUEST = "@@quickscan/CALCULATE_CARDS_REQUEST",
  CALCULATE_CARDS_SUCCESS = "@@quickscan/CALCULATE_CARDS_SUCCESS",
  CALCULATE_CARDS_FAILURE = "@@quickscan/CALCULATE_CARDS_FAILURE",
  CLEAR_CARDS = "@@quickscan/CLEAR_CARDS_REQUEST",
  // Account
  STORE_ACCOUNT = "@@quickscan/STORE_ACCOUNT",
  UPDATE_ACCOUNT_REQUEST = "@@quickscan/UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS = "@@quickscan/UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_FAILURE = "@@quickscan/UPDATE_ACCOUNT_FAILURE",
  LOAD_ACCOUNT_REQUEST = "@@quickscan/LOAD_ACCOUNT_REQUEST",
  LOAD_ACCOUNT_SUCCESS = "@@quickscan/LOAD_ACCOUNT_SUCCESS",
  LOAD_ACCOUNT_FAILURE = "@@quickscan/LOAD_ACCOUNT_FAILURE",
}
