import { put, takeEvery } from "@redux-saga/core/effects"

import { ActionTypes } from '../action-types'
import { deleteToken, statusLoggingOut, statusLoggedOut, logoutSuccess }
  from "../actions"

export function* watchLogout() {
  yield takeEvery(ActionTypes.LOGOUT_REQUEST, logout)
}

function* logout() {
  yield put(statusLoggingOut())
  yield put(deleteToken())
  yield put(logoutSuccess())
  yield put(statusLoggedOut())
}
