import { action } from 'typesafe-actions'

import ActionTypes from './types'
import { Client } from '../types'


export function createClientRequest(client: Client, callback: any) {
  return action(ActionTypes.CREATE_CLIENT_REQUEST, { client }, { callback })
}

export function createClientSuccess(client: Client) {
  return action(ActionTypes.CREATE_CLIENT_SUCCESS, { client })
}

export function createClientFailure(error: string) {
  return action(ActionTypes.CREATE_CLIENT_FAILURE, { error })
}

export function getClientRequest(clientId: number) {
  return action(ActionTypes.GET_CLIENT_REQUEST, { clientId })
}

export function getClientSuccess(client: Client) {
  return action(ActionTypes.GET_CLIENT_SUCCESS, { client })
}

export function getClientFailure(clientId: number) {
  return action(ActionTypes.GET_CLIENT_FAILURE, { clientId })
}

export function updateClientRequest(client: Client, callback: any) {
  return action(ActionTypes.UPDATE_CLIENT_REQUEST, { client }, { callback })
}

export function updateClientSuccess(client: Client) {
  return action(ActionTypes.UPDATE_CLIENT_SUCCESS, { client })
}

export function updateClientFailure(error: string) {
  return action(ActionTypes.UPDATE_CLIENT_FAILURE, { error })
}

export function deleteClientRequest(clientId: number) {
  return action(ActionTypes.DELETE_CLIENT_REQUEST, { clientId })
}

export function deleteClientSuccess() {
  return action(ActionTypes.DELETE_CLIENT_SUCCESS)
}

export function deleteClientFailure() {
  return action(ActionTypes.DELETE_CLIENT_FAILURE)
}

export function errorReset() {
  return action(ActionTypes.RESET_ERROR)
}
