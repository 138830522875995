import { ActionType } from 'typesafe-actions'

import { Client } from '../../../store/client/types'
import { ActionTypes } from './types'
import * as actions from './actions'
import { deepClone } from '../../../services/util'
import { Loan } from '../types'

type LoanAction = ActionType<typeof actions>

const initialState = {
  client: undefined as any as Client,
  loans: [] as Loan[],
  busyCount: 0,
  error: ''
}

export type LoanListState = typeof initialState

function reducer(state: LoanListState = initialState, action: LoanAction) {

  switch (action.type) {

    case ActionTypes.UPDATE_CLIENT:
      return Object.assign({}, state, {
        client: deepClone(action.payload)
      })

    case ActionTypes.UPDATE_LOAN_LIST:
      return Object.assign({}, state, {
        loans: deepClone(action.payload)
      })

    case ActionTypes.RESET_LOAN_LIST:
      return Object.assign({}, state, {
        loans: []
      })

    case ActionTypes.RESET_BUSY:
      return Object.assign({}, state, {
        busyCount: 0,
      })

    case ActionTypes.BEGIN_BUSY:
      return Object.assign({}, state, {
        busyCount: state.busyCount + 1,
      })

    case ActionTypes.END_BUSY:
      return Object.assign({}, state, {
        busyCount: state.busyCount - 1,
      })

    case ActionTypes.DELETE_LOAN_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case ActionTypes.RESET_LOAN_LIST_ERROR_MESSAGE:
      return {
        ...state,
        error: ''
      }

    default:
      return state
  }
}

export default reducer
