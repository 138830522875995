import {combineReducers} from 'redux'
import dataReducer, { DataState } from './data/reducer'

export interface ScenarioState {
  data: DataState,
}

export default combineReducers<ScenarioState>({
  data: dataReducer
})
