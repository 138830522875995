import { ActionTypes } from './action-types'
import { deepClone } from '../../services/util'
import { HedgeCard } from '../../services/hedge-card'
import { Account } from '.'
import { CardDisplayMode } from './types'
import { calcBusyCount } from '../proposal/proposal-detail/reducer'

const initialState = {
  cards: [] as HedgeCard[],
  account: undefined as Account | undefined,
  cardDisplayMode: 'list' as CardDisplayMode,
  busyCount: 0,
}

export type QuickscanState = typeof initialState

function reducer(state: QuickscanState = deepClone(initialState), action: any) {
  switch (action.type) {

    case ActionTypes.STORE_ACCOUNT:
    case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: deepClone(action.payload),
        busyCount: calcBusyCount(state.busyCount, -1)
      }

    case ActionTypes.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, +1)
      }

    case ActionTypes.UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        busyCount: state.busyCount - 1
      }

    case ActionTypes.LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: deepClone(action.payload)
      }

    case ActionTypes.LOAD_ACCOUNT_FAILURE:
      return {
        ...state,
        busyCount: state.busyCount - 1
      }

    case ActionTypes.CALCULATE_CARDS_REQUEST:
      return {
        ...state,
        busyCount: calcBusyCount(state.busyCount, -1)
      }

    case ActionTypes.CALCULATE_CARDS_SUCCESS:
      return {
        ...state,
        cards: deepClone(action.payload),
        busyCount: calcBusyCount(state.busyCount, -1)
      }

    case ActionTypes.CALCULATE_CARDS_FAILURE:
      return {
        ...state,
        busyCount: state.busyCount - 1
      }

    case ActionTypes.SET_CARD_DISPLAY_MODE: {
      const { mode } = action.payload
      return { ...state, cardDisplayMode: mode }
    }

    case ActionTypes.CLEAR_CARDS:
      return { ...state, cards: [] }

    case ActionTypes.RESET_QUICKSCAN:
      return deepClone(initialState)

    case ActionTypes.SET_BUSY_COUNT:
      return {
        ...state,
        busyCount: action.payload
      }

    default:
      return state
  }
}

export default reducer
