import { action } from 'typesafe-actions'
import { ActionTypes } from '../action-types'
import { deleteToken as doDeleteToken } from '../../../services/auth'

export function statusLoggedIn() {
  return action(ActionTypes.STATUS_LOGGED_IN)
}

export function statusLoggedOut() {
  return action(ActionTypes.STATUS_LOGGED_OUT)
}

export function statusLoggingIn() {
  return action(ActionTypes.STATUS_LOGGING_IN)
}

export function statusLoggingOut() {
  return action(ActionTypes.STATUS_LOGGING_OUT)
}

export function statusChangingPwd() {
  return action(ActionTypes.STATUS_CHANGING_PWD)
}

export function statusChangedPwd() {
  return action(ActionTypes.STATUS_CHANGED_PWD)
}

export function deleteToken() {
  doDeleteToken()
  return action(ActionTypes.DELETE_TOKEN)
}
