import { call, put, takeEvery } from "@redux-saga/core/effects"

import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import * as api from '../../../../services/api/proposal-summary'
import {
  generateProposalSummarySuccess,
  generateProposalSummaryFailed,
  generateTermsheetSuccess,
  generateTermsheetFailed,
  generateProposalSummaryPDFRequest,
  generateTermsheetPDFRequest,
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchLoadProposalSummaryPDF() {
  yield takeEvery(
    ActionTypes.GENERATE_PROPOSAL_SUMMARY_PDF_REQUEST,
    loadSummaryPDF
  )
}

function* loadSummaryPDF(action: BaseAction) {
  const { callback } = action.meta
  const { proposal } = action.payload
  try {
    const response = yield call(
      api.loadProposalSummaryPDF,
      proposal
    )
    yield put(generateProposalSummarySuccess())
    if (callback) {
      callback(response)
    }
  } catch (error) {
    yield put(generateProposalSummaryFailed())
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        generateProposalSummaryPDFRequest(proposal, callback)
      )
    }
  }
}

export function* watchLoadTermsheetPDF() {
  yield takeEvery(
    ActionTypes.GENERATE_TERMSHEET_PDF_REQUEST,
    loadTermsheetPDF
  )
}

function* loadTermsheetPDF(action: BaseAction) {
  const { callback } = action.meta
  const { proposal, termsheet } = action.payload
  try {
    const response = yield call(
      api.loadTermsheetPDF,
      proposal,
      termsheet
    )
    yield put(generateTermsheetSuccess())
    if (callback) {
      callback(response)
    }
  } catch (error) {
    yield put(generateTermsheetFailed())
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        generateTermsheetPDFRequest(proposal, termsheet, callback)
      )
    }
  }
}
