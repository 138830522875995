import { call, put, takeEvery } from "@redux-saga/core/effects"

import { Proposal } from '../../../types'
import {
    loadTermsheetSuccess,
    loadTermsheetFailed,
    loadTermsheetRequest
} from "../actions"
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import * as api from '../../../../services/api/proposal-summary'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchLoadTermsheet() {
    yield takeEvery(ActionTypes.LOAD_TERMSHEET_REQUEST, loadTermsheet)
}

function* loadTermsheet(action: BaseAction) {
    const proposal: Proposal = action.payload.proposal
    const { callback } = action.meta
    try {
        const response = yield call(
            api.getDefaultData,
            proposal,
        )
        yield put(loadTermsheetSuccess())
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(loadTermsheetFailed())
        console.error(error)
        if (isAzureAD() && isInvalidTokenError(error)) {
            yield renewAzureTokenAndPut(loadTermsheetRequest(proposal, callback))
        }
    }
}
