
export enum ActionTypes {
  LOGIN_REQUEST = "@@auth/LOGIN_REQUEST",
  LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS",
  LOGIN_FAILED = "@@auth/LOGIN_FAILED",
  CHANGE_PWD_REQUEST = "@@auth/CHANGE_PWD_REQUEST",
  CHANGE_PWD_SUCCESS = "@@auth/CHANGE_PWD_SUCCESS",
  CHANGE_PWD_PROCESSING = "@@auth/CHANGE_PWD_PROCESSING",
  CHANGE_PWD_FAILED = "@@auth/CHANGE_PWD_FAILED",
  LOGOUT_REQUEST = "@@auth/LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "@@auth/LOGOUT_SUCCESS",
  STATUS_LOGGED_IN = "@@auth/STATUS_LOGGED_IN",
  STATUS_LOG_FAILED = "@@auth/STATUS_LOG_FAILED",
  STATUS_LOGGED_OUT = "@@auth/STATUS_LOGGED_OUT",
  STATUS_CHANGING_PWD = "@@auth/STATUS_CHANGING_PWD",
  STATUS_CHANGED_PWD = "@@auth/STATUS_CHANGED_PWD",
  STATUS_LOGGING_IN = "@@auth/STATUS_LOGGING_IN",
  STATUS_LOGGING_OUT = "@@auth/STATUS_LOGGING_OUT",
  REFRESH_TOKEN_REQUEST = "@@auth/REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS = "@@auth/REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE = "@@auth/REFRESH_TOKEN_FAILURE",
  DELETE_TOKEN = "@@auth/DELETE_TOKEN",
  ENTER_LOCKDOWN_MODE = "@@auth/ENTER_LOCKDOWN_MODE",
  LOAD_USER_PROFILE_REQUEST = "@@auth/LOAD_USER_PROFILE_REQUEST",
  LOAD_USER_PROFILE_SUCCESS = "@@auth/LOAD_USER_PROFILE_SUCCESS",
}
