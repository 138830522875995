import { call, put, takeEvery, select } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import {
  calculatePriceDetailSuccess,
  calculatePriceDetailFailure,
  loadReferenceRateRequest,
  calculatePriceDetailRequest
} from "../actions"
import { AppState } from "../../.."
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


/**
 * Calculate the hedge's receive leg's spread given a required margin
 */
export function* watchCalculatePriceDetail() {
  yield takeEvery(ActionTypes.CALCULATE_PRICE_DETAIL_REQUEST, calculatePriceDetail)
}

function* calculatePriceDetail(action: BaseAction) {
  const proposalId: number = action.payload.id
  const pricingDate: string | undefined = action.payload.pricingDate
  const margin: number | undefined = action.payload.margin
  const finalPrice: number | undefined = action.payload.finalPrice

  try {
    const priceDetail = yield call(
      api.calculatePriceDetail,
      proposalId,
      pricingDate,
      margin,
      finalPrice)

    yield put(calculatePriceDetailSuccess(priceDetail))

    const getProposal = (state: AppState) => state.proposal.proposalDetail.proposal
    const proposal = yield select(getProposal)
    yield put(loadReferenceRateRequest(proposal))
  } catch (error) {
    console.log(error)
    yield put(calculatePriceDetailFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        calculatePriceDetailRequest(proposalId, pricingDate, margin, finalPrice)
      )
    }
  }
}
