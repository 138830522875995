import { call, put, takeEvery } from "@redux-saga/core/effects"
import { ActionTypes } from '../action-types'
import * as accountApi from '../../../services/api/account'
import { BaseAction } from "../../../services/action"
import {
  calculateCardsSuccess,
  calculateCardsRequest,
  calculateCardsFailure
} from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../util"
import { isAzureAD } from "../../../services/api/azure-ad"

export function* watchCalculateCards() {
  yield takeEvery(ActionTypes.CALCULATE_CARDS_REQUEST, calculateCards)
}

function* calculateCards(action: BaseAction) {
  const { account } = action.payload
  try {
    const cards = yield call(accountApi.calcCards, account)
    yield put(calculateCardsSuccess(cards))
  } catch (error) {
    console.error(error)
    yield put(calculateCardsFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(calculateCardsRequest(account))
    }
  }
}
