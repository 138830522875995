import { range } from 'lodash'
import { InterestRate, FloatingRate, FixedRate } from '../store/loan/types'

// Money format for use by numbro
export const money = { mantissa: 2, thousandSeparated: true }

export const noDataTitle = 'No Data'

export function createChoices(array: any[]) {
	return array.map(k => ({
		key: k,
		text: k,
		value: k,
	}))
}

export function getCurveChoices(ccy: string): string[] {
	interface Lookup {
		[key: string]: string[]
	}

	const choices: Lookup = {
		THB: ['THBFIX'],
		USD: ['LIBOR'],
		EUR: ['EURIBOR'],
		JPY: ['JPY-LIBOR'],
	}

	return choices[ccy]
}

export function getCurrencyChoices() {
	return ['THB', 'USD', 'EUR', 'JPY']
}

export function numFormat(num: number) {
	return num.toLocaleString()
}

// Round number n to 'dec' decimal places
export function round(n: number, dec: number) {
	return Math.round(n * Math.pow(10, dec)) / Math.pow(10, dec)
}

// See: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/5344074#5344074
export function deepClone<T>(obj: T): T {
	if (obj === undefined) return (undefined as any) as T
	return JSON.parse(JSON.stringify(obj)) as T
}

/** Returns true if 2 objects' contents are equal (using JSON.stringify) */
export function deepEquals(obj1: any, obj2: any) {
	return JSON.stringify(obj1) === JSON.stringify(obj2)
}

/** Returns a random n-digit hexadecimal as a string */
export function randomId(len: number): string {
	const maxlen = 8
	const min = Math.pow(16, Math.min(len, maxlen) - 1)
	const max = Math.pow(16, Math.min(len, maxlen)) - 1
	const n = Math.floor(Math.random() * (max - min + 1)) + min
	let r = n.toString(16)
	while (r.length < len) {
		r = r + randomId(len - maxlen)
	}
	return r
}

/** Returns a random integer from the range [low, high] (inclusive) */
function randomIntFromInterval(low: number, high: number): number {
	return Math.floor(Math.random() * (high - low + 1) + low)
}

/** Returns a random n-digit string with each digit between 0 to 9 */
export function randomNumeric(len: number): string {
	return range(len)
		.map(() => randomIntFromInterval(0, 9))
		.join('')
}

export function timestamp(): number {
	return new Date().valueOf()
}

export function copyToClipboard(data: any) {
	const textArea = document.createElement('textarea')
	textArea.value = data
	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()
	const success = document.execCommand('copy')
	if (!success) {
		console.error('copy to clipboard failed')
	}
	document.body.removeChild(textArea)
}

type POS = 'loan_fixed' | 'loan_float'

export function genRateTextPercent(pos: POS, value: any) {
	if (value === undefined || value === null) return ''
	let cleanV = value
		.replace(/\s/g, '')
		.replace('-', '')
		.replace('+', '')
		.replace('%', '')
	let prefix = '+'
	if (value.search('-') > -1) prefix = '-'
	if (pos === 'loan_fixed') prefix = ''
	let newV = `${prefix} ${cleanV} %`
	return newV
}

export function getOnlyRateNumberFromInterestRate(interestRate: InterestRate) {
	const rate =
		interestRate.rateType === 'fixed'
			? (interestRate as FixedRate).spread
			: (interestRate as FloatingRate).spread
	return rate
}

export function validatePercentage(percent: number, defaultForNaN: number = 0) {
	percent = (isNaN(percent)) ? defaultForNaN : percent
	return Math.max(0, Math.min(100, percent))
}

export const fontFamily = 'Roboto,Helvetica,Arial,sans-serif'
