import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import ActionTypes from './action-types'
import { ScenarioSummary, ScenarioCashflow } from '../types'
import { CustomRiskFactor } from './../types'

const initialState = {
  summaryBusyCount: 0,
  cashflowBusyCount: 0,
  summaryList: Array<ScenarioSummary>(0),
  cashFlows: [] as ScenarioCashflow[],
  customRiskFactors: [] as CustomRiskFactor[],
}

type Action = ActionType<typeof actions>
export type DataState = typeof initialState

function reducer(
  state: DataState = initialState,
  action: Action) {

  switch (action.type) {
    case ActionTypes.CALCULATE_SUMMARY_REQUEST:
      return {
        ...state,
        summaryBusyCount: state.summaryBusyCount + 1,
      }

    case ActionTypes.CALCULATE_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryBusyCount: state.summaryBusyCount - 1,
        summaryList: action.payload.summaryList,
      }

    case ActionTypes.CALCULATE_SUMMARY_FAILURE:
      return {
        ...state,
        summaryBusyCount: state.summaryBusyCount - 1,
      }

    case ActionTypes.CALCULATE_CASHFLOW_REQUEST:
      return {
        ...state,
        cashflowBusyCount: state.cashflowBusyCount + 1,
      }

    case ActionTypes.CALCULATE_CASHFLOW_SUCCESS:
      return {
        ...state,
        cashFlows: action.payload.cashFlows,
        cashflowBusyCount: state.cashflowBusyCount - 1,
      }

    case ActionTypes.CALCULATE_CASHFLOW_FAILURE:
      return {
        ...state,
        cashflowBusyCount: state.cashflowBusyCount - 1,
      }

    case ActionTypes.LOAD_CUSTOM_RISK_FACTOR_REQUEST:
      return {
        ...state,
        summaryBusyCount: state.summaryBusyCount + 1,
      }

    case ActionTypes.LOAD_CUSTOM_RISK_FACTOR_SUCCESS:
      return {
        ...state,
        customRiskFactors: action.payload.customRiskFactors,
        summaryBusyCount: state.summaryBusyCount - 1,
      }

    case ActionTypes.LOAD_CUSTOM_RISK_FACTOR_FAILURE:
      return {
        ...state,
        customRiskFactors: [],
        summaryBusyCount: state.summaryBusyCount - 1,
      }

    case ActionTypes.CREATE_CUSTOM_RISK_FACTOR_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.CREATE_CUSTOM_RISK_FACTOR_SUCCESS:
      return {
        ...state,
        customRiskFactors: updateCustomRiskFactor(state, action.payload.customRiskFactor),
      }

    case ActionTypes.CREATE_CUSTOM_RISK_FACTOR_FAILURE:
      return {
        ...state,
        customRiskFactors: [],
      }

    case ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_SUCCESS:
      return {
        ...state,
        customRiskFactors: updateCustomRiskFactor(state, action.payload.customRiskFactor),
      }

    case ActionTypes.UPDATE_CUSTOM_RISK_FACTOR_FAILURE:
      return {
        ...state,
        customRiskFactors: [],
      }

    case ActionTypes.DELETE_CUSTOM_RISK_FACTOR_SUCCESS:
      const { customRiskFactorId } = action.payload
      const newCustomRiskFactors = state.customRiskFactors.filter(factor => (
        factor.id !== customRiskFactorId
      ))
      return {
        ...state,
        customRiskFactors: newCustomRiskFactors
      }

    default:
      return state
  }
}

/**
 * Update new customRiskFactor result from creating or updating to an existing customRiskFactors
 */
function updateCustomRiskFactor(
  state: DataState,
  newFactor: CustomRiskFactor
): CustomRiskFactor[] {
  const customRiskFactors = state.customRiskFactors
  const foundIndex = customRiskFactors.findIndex(c => (c.id === newFactor.id))

  if (foundIndex >= 0) {
    customRiskFactors[foundIndex] = newFactor
  } else {
    customRiskFactors.push(newFactor)
  }

  return customRiskFactors
}

export default reducer
