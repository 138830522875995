import { CashflowInput, CustomRiskFactor } from './../../store/scenario/types'
import { ax, createHeader, handleError, toCamelCase, toSnakeCase } from "./util"
import { ScenarioSummary, Dir, Direction } from "../../store/scenario/types"

export async function calculateCashFlow(input: CashflowInput) {
  try {
    const { proposalId, asOf, currency, scenario } = input
    const { direction, riskFactors, customRiskFactors } = scenario
    const newDirection = revertDirection(direction)
    const data = toSnakeCase({
      asOf,
      currency,
      direction: newDirection,
      riskFactors,
      customRiskFactors,
    })
    const response = await ax.post(`/proposals/${proposalId}/scenario_cashflow/`,
      data, createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

export async function getScenariosSummaries(proposalId: number) {
  try {
    const response = await ax.post(`/proposals/${proposalId}/scenario_summary/`, {}, createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

export async function calculateSummary(
  proposalId: number,
  asOf: string,
  currency: string,
): Promise<ScenarioSummary[]> {

  try {
    const data = toSnakeCase({ currency, asOf })
    const response = await ax.post(
      `/proposals/${proposalId}/scenario_summary/`,
      data, // TODO: tell the backend which currency to denominate the data
      createHeader())

    const result = toCamelCase(response.data)
    return result.map((summary: any) => {
      const { scenario } = summary
      return {
        ...summary,
        scenario: {
          ...scenario,
          direction: convertDirection(scenario.direction),
        }
      }
    })
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

/**
 * Backend stores Direction as 3-array of {-1, 0, +1, null}.
 * We want to convert it into a typed Direction.
 */
function convertDirection(direction: number[]): Direction {

  function convertDir(n: any): Dir {
    switch (n) {
      case -1: return Dir.DOWN
      case 0: return Dir.FLAT
      case 1: return Dir.RISE
      default: return Dir.NULL
    }
  }

  return direction.map((n: number) => convertDir(n)) as Direction
}

function revertDirection(direction: Direction): (number | null)[] {

  function reverseDir(dir: Dir): number | null {
    switch (dir) {
      case Dir.DOWN: return -1
      case Dir.FLAT: return 0
      case Dir.RISE: return 1
      default: return null
    }
  }

  return direction.map((dir: Dir) => reverseDir(dir)) as (number | null)[]
}

export async function loadCustomRiskFactor(proposalId: number) {
  try {
    let response = await ax.get(`/proposals/${proposalId}/custom_risk_factor/`,
      createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

export async function createCustomRiskFactor(proposalId: number, input: CustomRiskFactor) {
  try {
    let data = toSnakeCase(input)
    delete input.id
    let response = await ax.post(`/proposals/${proposalId}/create_custom_risk_factor/`,
      data, createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

export async function updateCustomRiskFactor(proposalId: number, input: CustomRiskFactor) {
  try {
    const data = toSnakeCase(input)
    let response = await ax.post(`/proposals/${proposalId}/update_custom_risk_factor/`,
      data, createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}

export async function deleteCustomRiskFactor(proposalId: number, input: any) {
  try {
    const data = toSnakeCase(input)
    let response = await ax.post(`/proposals/${proposalId}/delete_custom_risk_factor/`,
      data, createHeader())
    return toCamelCase(response.data)
  }
  catch (error) {
    throw Error(handleError(error))
  }
}
