import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as hedgeApi from '../../../../services/api/hedge'
import * as proposalApi from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import {
  updateHedgeSuccess,
  updateHedgeRequest,
  updateHedgeFailed
} from "../actions"
import { Hedge } from "../../../hedge/types"
import { Proposal } from "../.."
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


export function* watchUpdateHedge() {
  yield takeEvery(ActionTypes.UPDATE_HEDGE_REQUEST, updateHedge)
}

/** 
 * Update the proposal's hedge on server
 */
function* updateHedge(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const hedge: Hedge = action.payload.hedge
  const callback = action.payload.callback

  try {
    // Save the hedge to server
    const newHedge = yield call(hedgeApi.createHedge, hedge)

    // Update proposal
    const newProposal = yield call(proposalApi.updateProposal,
      proposal.id,
      undefined,
      undefined,
      newHedge.id,
    )

    yield put(updateHedgeSuccess(newProposal))
    callback()

  } catch (error) {
    console.log(error)
    yield put(updateHedgeFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateHedgeRequest(proposal, hedge, callback))
    }
  }
}
