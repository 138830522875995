enum ActionTypes {
  // Proposal
  LOAD_PROPOSAL_REQUEST = "@@proposal/LOAD_PROPOSAL_REQUEST",
  LOAD_PROPOSAL_SUCCESS = "@@proposal/LOAD_PROPOSAL_SUCCESS",
  LOAD_PROPOSAL_FAILURE = "@@proposal/LOAD_PROPOSAL_FAILURE",
  DELETE_PROPOSAL_REQUEST = "@@proposal/DELETE_PROPOSAL_REQUEST",
  DELETE_PROPOSAL_SUCCESS = "@@proposal/DELETE_PROPOSAL_SUCCESS",
  DELETE_PROPOSAL_FAILURE = "@@proposal/DELETE_PROPOSAL_FAILURE",
  UPDATE_PROPOSAL_REQUEST = "@@proposal/UPDATE_PROPOSAL_REQUEST",
  UPDATE_PROPOSAL_SUCCESS = "@@proposal/UPDATE_PROPOSAL_SUCCESS",
  UPDATE_PROPOSAL_FAILURE = "@@proposal/UPDATE_PROPOSAL_FAILURE",
  REFRESH_PROPOSAL_REQUEST = "@@proposal/REFRESH_PROPOSAL_REQUEST",
  REFRESH_PROPOSAL_SUCCESS = "@@proposal/REFRESH_PROPOSAL_SUCCESS",
  REFRESH_PROPOSAL_FAILURE = "@@proposal/REFRESH_PROPOSAL_FAILURE",

  //Loan Page
  UPDATE_LOAN_REQUEST = "@@proposal/UPDATE_LOAN_REQUEST",
  UPDATE_LOAN_SUCCESS = "@@proposal/UPDATE_LOAN_SUCCESS",
  UPDATE_LOAN_FAILURE = "@@proposal/UPDATE_LOAN_FAILURE",
  UPDATE_PRE_CONFIGURED_LOAN_REQUEST = "@@proposal/UPDATE_PRE_CONFIGURED_LOAN_REQUEST",
  UPDATE_PRE_CONFIGURED_LOAN_SUCCESS = "@@proposal/UPDATE_PRE_CONFIGURED_LOAN_SUCCESS",
  UPDATE_PRE_CONFIGURED_LOAN_FAILURE = "@@proposal/UPDATE_PRE_CONFIGURED_LOAN_FAILURE",

  //Hedge Page
  UPDATE_HEDGE_REQUEST = "@@proposal/UPDATE_HEDGE_REQUEST",
  UPDATE_HEDGE_SUCCESS = "@@proposal/UPDATE_HEDGE_SUCCESS",
  UPDATE_HEDGE_FAILURE = "@@proposal/UPDATE_HEDGE_FAILURE",

  // Active card
  SAVE_ACTIVE_CARD_REQUEST = "@@proposal/SAVE_ACTIVE_CARD_REQUEST",
  SAVE_ACTIVE_CARD_SUCCESS = "@@proposal/SAVE_ACTIVE_CARD_SUCCESS",
  SAVE_ACTIVE_CARD_FAILURE = "@@proposal/SAVE_ACTIVE_CARD_FAILURE",

  /* Remove? */
  INCOMPLETE_HEDGE = "@@proposal/INCOMPLETE_HEDGE",
  CLEAR_LOAN = "@@proposal/CLEAR_LOAN",
  CLEAR_HEDGE = "@@proposal/CLEAR_HEDGE",

  // Pricing
  RECALC_PROPOSAL_PRICE_REQUEST = "@@pricer/RECALC_PROPOSAL_PRICE_REQUEST ",
  RECALC_PROPOSAL_PRICE_SUCCESS = "@@pricer/RECALC_PROPOSAL_PRICE_SUCCESS",
  RECALC_PROPOSAL_PRICE_FAILURE = "@@pricer/RECALC_PROPOSAL_PRICE_FAILURE",
  CALCULATE_PRICE_DETAIL_REQUEST = "@@pricer/CALCULATE_PRICE_DETAIL_REQUEST",
  CALCULATE_PRICE_DETAIL_SUCCESS = "@@pricer/CALCULATE_PRICE_DETAIL_SUCCESS",
  CALCULATE_PRICE_DETAIL_FAILURE = "@@pricer/CALCULATE_PRICE_DETAIL_FAILURE",
  UPDATE_PROPOSAL_PRICE_REQUEST = "@@pricer/UPDATE_PROPOSAL_PRICE_REQUEST ",
  UPDATE_PROPOSAL_PRICE_SUCCESS = "@@pricer/UPDATE_PROPOSAL_PRICE_SUCCESS",
  UPDATE_PROPOSAL_PRICE_FAILURE = "@@pricer/UPDATE_PROPOSAL_PRICE_FAILURE",

  // Discussion Summary
  UPDATE_DISCUSSION_SUMMARY_REQUEST = "@@discussionSummary/UPDATE_DISCUSSION_SUMMARY_REQUEST",
  UPDATE_DISCUSSION_SUMMARY_SUCCESS = "@@discussionSummary/UPDATE_DISCUSSION_SUMMARY_SUCCESS",
  UPDATE_DISCUSSION_SUMMARY_FAILURE = "@@discussionSummary/UPDATE_DISCUSSION_SUMMARY_FAILURE",
  LOAD_TERMSHEET_REQUEST = "@@discussionSummary/LOAD_TERMSHEET_REQUEST",
  LOAD_TERMSHEET_SUCCESS = "@@discussionSummary/LOAD_TERMSHEET_SUCCESS",
  LOAD_TERMSHEET_FAILURE = "@@discussionSummary/LOAD_TERMSHEET_FAILURE",
  GENERATE_TERMSHEET_PDF_REQUEST = "@@discussionSummary/GENERATE_TERMSHEET_PDF_REQUEST",
  GENERATE_TERMSHEET_PDF_SUCCESS = "@@discussionSummary/GENERATE_TERMSHEET_PDF_SUCCESS",
  GENERATE_TERMSHEET_PDF_FAILURE = "@@discussionSummary/GENERATE_TERMSHEET_PDF_FAILURE",
  GENERATE_PROPOSAL_SUMMARY_PDF_REQUEST = "@@discussionSummary/GENERATE_PROPOSAL_SUMMARY_PDF_REQUEST",
  GENERATE_PROPOSAL_SUMMARY_PDF_SUCCESS = "@@discussionSummary/GENERATE_PROPOSAL_SUMMARY_PDF_SUCCESS",
  GENERATE_PROPOSAL_SUMMARY_PDF_FAILURE = "@@discussionSummary/GENERATE_PROPOSAL_SUMMARY_PDF_FAILURE",
  REQUEST_DISCUSSION_SUMMARY_REPORT_REQUEST = "@@discussionSummary/REQUEST_DISCUSSION_SUMMARY_REPORT_REQUEST",
  REQUEST_DISCUSSION_SUMMARY_REPORT_SUCCESS = "@@discussionSummary/REQUEST_DISCUSSION_SUMMARY_REPORT_SUCCESS",
  REQUEST_DISCUSSION_SUMMARY_REPORT_FAILURE = "@@discussionSummary/REQUEST_DISCUSSION_SUMMARY_REPORT_FAILURE",

  // Reference rate
  LOAD_REFERENCE_RATE_REQUEST = "@@proposal/LOAD_REFERENCE_RATE_REQUEST",
  LOAD_REFERENCE_RATE_SUCCESS = "@@proposal/LOAD_REFERENCE_RATE_SUCCESS",
  LOAD_REFERENCE_RATE_FAILED = "@@proposal/LOAD_REFERENCE_RATE_FAILED",
  RESET_REFERENCE_RATE = "@@proposal/RESET_REFERENCE_RATE",
}

export default ActionTypes
