import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/proposal'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { updateProposalPriceSuccess, updateProposalPriceFailure, updateProposalPriceRequest } from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


/**
 * Update the proposal with the given price details
 */
export function* watchUpdateProposalPrice() {
  yield takeEvery(ActionTypes.UPDATE_PROPOSAL_PRICE_REQUEST, updatePrice)
}

function* updatePrice(action: BaseAction) {
  const id: number = action.payload.id
  const pricingDate: string | undefined = action.payload.pricingDate
  const tradeDate: string | undefined = action.payload.tradeDate
  const margin: number | undefined = action.payload.margin
  const finalPrice: number | undefined = action.payload.finalPrice

  try {
    const proposal = yield call(
      api.updateProposalPrice,
      id,
      pricingDate,
      tradeDate,
      margin,
      finalPrice)

    yield put(updateProposalPriceSuccess(proposal))
  } catch (error) {
    console.log(error)
    yield put(updateProposalPriceFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(updateProposalPriceRequest(
        id,
        pricingDate,
        tradeDate,
        margin,
        finalPrice
      ))
    }
  }
}
