enum ActionTypes {
  GET_CLIENT_LIST_REQUEST = "@@client/GET_CLIENT_LIST_REQUEST",
  GET_CLIENT_LIST_SUCCESS = "@@client/GET_CLIENT_LIST_SUCCESS",
  GET_CLIENT_LIST_FAILURE = "@@client/GET_CLIENT_LIST_FAILURE",
  DELETE_CLIENT_REQUEST = "@@client/DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS = "@@client/DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE = "@@client/DELETE_CLIENT_FAILURE",
  DELETE_CLIENT_FAILURE_DONE = "@@client/DELETE_CLIENT_FAILURE_DONE"
}

export default ActionTypes
