import { connect } from 'react-redux'
import { getEmail, getError, loginStatus } from '../../../store/auth/selectors'
import { loginRequest, logoutRequest } from '../../../store/auth/actions'
import { AppState } from '../../../store'
import Login from './components/login'

const mapStateToProps = (state: AppState) => {
  return {
    email: getEmail(state),
    error: getError(state),
    loginStatus: loginStatus(state),
  }
}

const mapDispatchToProps = {
  onLogin: loginRequest,
  onLogout: logoutRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
