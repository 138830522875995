import { action } from 'typesafe-actions'

import ActionTypes from './types'
import { Client } from '../types'


export function getClientListRequest() {
  return action(ActionTypes.GET_CLIENT_LIST_REQUEST)
}

export function getClientListSuccess(clients: Client[]) {
  return action(ActionTypes.GET_CLIENT_LIST_SUCCESS, clients)
}

export function getClientListFailure() {
  return action(ActionTypes.GET_CLIENT_LIST_FAILURE)
}

export function deleteClientRequest(clientId: number) {
  return action(ActionTypes.DELETE_CLIENT_REQUEST, { clientId })
}

export function deleteClientSuccess() {
  return action(ActionTypes.DELETE_CLIENT_SUCCESS)
}

export function deleteClientFailure() {
  return action(ActionTypes.DELETE_CLIENT_FAILURE)
}

export function deleteClientFailureDone() {
  return action(ActionTypes.DELETE_CLIENT_FAILURE_DONE)
}
