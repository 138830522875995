import { DiscussionSummaryInput } from './../../store/proposal/types';
import { ax, createHeader, toSnakeCase, toCamelCase } from "./util"
import { Proposal } from '../../store/proposal/types';
import { TermsheetStructure } from "../../pages/proposal/proposal-summary/components/termsheet/utils";

export async function getDefaultData(
    proposal: Proposal
): Promise<TermsheetStructure> {
    const json = await ax.post(
        `/termsheet/${proposal!.id}/calculate/`,
        { 'as_of': proposal.hedge!.pricingDate },
        createHeader())
    console.assert(json.status === 200)
    return toCamelCase(json.data)
}

export async function loadTermsheetPDF(
    proposal: Proposal,
    termsheet: TermsheetStructure
): Promise<any> {
    const json = await ax.post(
        `/termsheet/${proposal!.id}/generate/`,
        toSnakeCase(termsheet),
        {
            responseType: 'blob',
            headers: createHeader().headers
        }
    )
    console.assert(json.status === 200)
    var url = URL.createObjectURL(json.data)
    return url
}

export async function loadProposalSummaryPDF(proposal: Proposal): Promise<any> {
    const json =
        await ax.get(
            `/proposals/${proposal!.id}/proposal_summary_report/`,
            {
                responseType: 'blob',
                headers: createHeader().headers
            }
        )
    console.assert(json.status === 200)
    var url = URL.createObjectURL(json.data)
    return url
}

export async function updateDiscussionSummary(
    summary: DiscussionSummaryInput
    , id: number
): Promise<TermsheetStructure> {
    if (summary.followUpDate === null)
        delete summary.followUpDate

    const json = await ax.patch(
        `/discussion/${summary.id}/`,
        toSnakeCase(summary),
        createHeader())
    console.assert(json.status === 201)
    return toCamelCase(json.data)
}
