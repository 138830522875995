import { action } from 'typesafe-actions'

import { ActionTypes } from '../action-types'

export function refreshTokenRequest(token: string) {
  return action(ActionTypes.REFRESH_TOKEN_REQUEST, { token })
}

export function refreshTokenSuccess(token: string, payload: any) {
  return action(ActionTypes.REFRESH_TOKEN_SUCCESS, { token, payload })
}

export function refreshTokenFailure(error: any) {
  return action(ActionTypes.REFRESH_TOKEN_FAILURE, { error })
}
