import { call, put, take, takeEvery } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/client'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { deleteClientSuccess, getClientListRequest, deleteClientFailure, deleteClientRequest }
  from "../actions"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchDeleteClient() {
  yield takeEvery(ActionTypes.DELETE_CLIENT_REQUEST, deleteClient)
}

function* deleteClient(action: BaseAction) {
  const { clientId } = action.payload

  try {
    yield call(api.deleteClient, clientId)
    yield put(deleteClientSuccess())
  }
  catch (error) {
    // Inform user of failure
    yield put(deleteClientFailure())
    // User clicks 'ok' on error message, and I'll receive this action
    yield take(ActionTypes.DELETE_CLIENT_FAILURE_DONE)
    console.error(error)
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(deleteClientRequest(clientId))
    }
  }
  finally {
    yield put(getClientListRequest())
  }
}
