import * as React from 'react'
import { Paper, Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles'

const styles = ({ palette, breakpoints, spacing }: Theme) => createStyles({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing.unit * 3,
    marginRight: spacing.unit * 3,
    [breakpoints.up(400 + spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${spacing.unit * 2}px ${spacing.unit * 3}px ${spacing.unit * 3}px`,
  },
  avatar: {
    margin: spacing.unit,
    backgroundColor: palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: spacing.unit,
  },
  submit: {
    marginTop: spacing.unit * 3,
  },
});

const SessionExpiredPage = ({ onAcknowledgeExpired, classes }: any) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Your session has expired
          </Typography>
          <form className={classes.form}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onAcknowledgeExpired}
              className={classes.submit}>
              OK
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  )
}

export default withStyles(styles)(SessionExpiredPage)
