import { combineReducers } from 'redux'
import clientList, { ClientListState } from './client-list/reducer'
import clientEdit, { ClientEditState } from './client-edit/reducer'
import { Client } from './types'

export type Client = Client

export interface ClientState {
  clientList: ClientListState
  clientEdit: ClientEditState
}

export default combineReducers<ClientState>({
  clientList,
  clientEdit,
})
