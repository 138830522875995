import { action } from 'typesafe-actions'

import { ActionTypes } from '../action-types'

export function loginRequest(email: string, password: string) {
  return action(ActionTypes.LOGIN_REQUEST, { email, password })
}

export function loginSuccess(token: string, payload: any) {
  return action(ActionTypes.LOGIN_SUCCESS, { token, payload })
}

export function loginFail(email: string, errorMsg: string) {
  return action(ActionTypes.LOGIN_FAILED, { email, errorMsg })
}
