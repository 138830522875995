import { call, put, takeEvery, select } from "@redux-saga/core/effects"

import * as api from '../../../../services/api/scenario'
import ActionTypes from "../action-types"
import { BaseAction } from "../../../../services/action"
import {
  createCustomRiskFactorSuccess,
  createCustomRiskFactorFailure,
  calculateSummaryRequest,
  loadCustomRiskFactorRequest,
  createCustomRiskFactorRequest
} from '../actions'
import { toSnakeCase } from '../../../../services/api/util'
import { CustomRiskFactor } from '../../types'
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"

export function* watchCreateCustomRiskFactor() {
  yield takeEvery(ActionTypes.CREATE_CUSTOM_RISK_FACTOR_REQUEST, createCustomRiskFactor)
}

function* createCustomRiskFactor(action: BaseAction) {
  const { proposalId, customRiskFactor, currency } = action.payload
  try {
    const data = toSnakeCase(customRiskFactor)
    const result: CustomRiskFactor = yield call(api.createCustomRiskFactor, proposalId, data)
    yield put(createCustomRiskFactorSuccess(result))

    // refresh custom-risk-factor
    yield put(loadCustomRiskFactorRequest(proposalId))

    // Refresh summaryList
    const state = yield select()
    const proposal = state.proposal.proposalDetail.proposal
    yield put(calculateSummaryRequest(proposal.id, proposal.hedge!.pricingDate, currency))

  } catch (error) {
    console.error(error)
    yield put(createCustomRiskFactorFailure())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(
        createCustomRiskFactorRequest(proposalId, customRiskFactor, currency)
      )
    }
  }
}
