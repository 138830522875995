import * as React from 'react'
import { LoginStatus } from '../../../../store/auth/types'
import LoginPage from './login-page'
import ExpiryWarningPage from './expiry_warning_page'
import SessionExpiredPage from './session_expired_page'

interface Props {
  email: string
  onLogin: any
  onLogout: any
  error: string
  loginStatus: LoginStatus
}

function Login(props: Props) {

  return (
    <div>
      {[LoginStatus.LOGGED_OUT, LoginStatus.LOGIN_FAILED].indexOf(props.loginStatus) > -1 &&
        <LoginPage {...props} />
      }
      {props.loginStatus === LoginStatus.LOGGING_OUT &&
        <ExpiryWarningPage {...props} />
      }
      {props.loginStatus === LoginStatus.EXPIRED &&
        <SessionExpiredPage {...props} />
      }
    </div>
  )
}

export default Login
