import { call, put, takeEvery } from "@redux-saga/core/effects"

import * as accountApi from '../../../../services/api/account'
import ActionTypes from "../types"
import { BaseAction } from "../../../../services/action"
import { Proposal } from "../.."
import { HedgeCard } from "../../../../services/hedge-card"
import {
  saveActiveCardSuccess,
  updateLoanRequest,
  saveActiveCardRequest,
  saveActiveCardFailed,
} from "../actions"
import { createStandardLoan } from "../../../../services/loan"
import { renewAzureTokenAndPut, isInvalidTokenError } from "../../../util"
import { isAzureAD } from "../../../../services/api/azure-ad"


export function* watchSaveActiveCard() {
  yield takeEvery(ActionTypes.SAVE_ACTIVE_CARD_REQUEST, saveActiveCard)
}

/** When user chooses a card for first time, or replaces the card. */
function* saveActiveCard(action: BaseAction) {
  const proposal: Proposal = action.payload.proposal
  const card: HedgeCard = action.payload.card
  try {
    card.account = proposal.accountId

    // Persist the card to server
    const updatedCard = yield call(accountApi.saveCard, card)

    // Create the 'standard' loan defined by the card
    const loan = createStandardLoan(updatedCard)

    // Update the loan, which will also update the proposal
    yield put(updateLoanRequest(proposal, loan, updatedCard))

    yield put(saveActiveCardSuccess())
  } catch (error) {
    console.error(error)
    yield put(saveActiveCardFailed())
    if (isAzureAD() && isInvalidTokenError(error)) {
      yield renewAzureTokenAndPut(saveActiveCardRequest(proposal, card))
    }
  }
}
