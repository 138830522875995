import numbro from "numbro"

export const defaultFullNotional: number = 10000000 // ten million dollars

export function getDecimalForEachCCY(currency: string) {
  switch (currency) {
    case "EURUSD":
      return 4
    case "JPYTHB":
      return 4
    default: return 2
  }
}

export function getCCYConventionPair(payLegCCY: string, receiveLegCCY: string): string {
  if (isHigherTier(payLegCCY, receiveLegCCY)) {
    return `${payLegCCY}${receiveLegCCY}`
  } else return `${receiveLegCCY}${payLegCCY}`
}

function isHigherTier(payLegCCY: string, receiveLegCCY: string): boolean {
  const tierPayLeg = tierCCY.indexOf(payLegCCY)
  const tierReceiveLeg = tierCCY.indexOf(receiveLegCCY)
  if (tierPayLeg > tierReceiveLeg) {
    return true
  } else return false
}

export const tierCCY = ['EUR', 'USD', 'JPY', 'THB']

export const interestRateDecimalPoint = 2

export function formatInterestRate(
  number: Number,
  forceSign: boolean = true
): string {
  return numbro(number).format({
    output: 'percent',
    forceSign: forceSign,
    mantissa: interestRateDecimalPoint
  })
}
