import * as React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import { LoginStatus } from '../../store/auth/types'
import Main from '../main'
import Auth from '../../pages/auth/login'
import { refreshToken } from '../../services/auth'
import { initIcons } from '../../components/icons/icons'

interface Props {
  loginStatus: LoginStatus
  statusLoggingOut: () => void
  logout: () => void
  refreshToken: (token: string) => void
}

function App(props: Props) {
  initIcons()
  refreshToken(props.loginStatus, props.refreshToken)

  return (
    <React.Fragment>
      <CssBaseline />
      {
        props.loginStatus === LoginStatus.LOGGED_IN ?
          <Main />
          :
          <Auth />
      }
    </React.Fragment>
  )
}

export default App
