import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { LoginStatus, ChangePwdStatus, UserProfile } from './types'
import { ActionTypes } from './action-types'
import { Client } from '../client/types'

export type AuthAction = ActionType<typeof actions>

const initialState = {
  email: '',
  error: '',
  changePwdError: [] as string[],
  payload: '',
  status: LoginStatus.LOGGED_OUT,
  statusChangePwd: ChangePwdStatus.CHANGE_PWD_PROCESSING,
  token: '',
  lockdownClient: getLockdownClient(),
  me: undefined as UserProfile | undefined,
}

export type AuthState = typeof initialState

const reducer: Reducer<AuthState, AuthAction> =
  (state: AuthState = initialState, action: AuthAction) => {
    switch (action.type) {

      case ActionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          email: action.payload.payload.email,
          error: '',
          payload: action.payload.payload,
          token: action.payload.token,
        }

      case ActionTypes.LOAD_USER_PROFILE_SUCCESS:
        return {
          ...state,
          me: action.payload.me,
        }

      case ActionTypes.LOGIN_FAILED:
        return {
          ...state,
          email: action.payload.email,
          error: action.payload.errorMsg,
          status: LoginStatus.LOGIN_FAILED,
          me: undefined,
        }

      case ActionTypes.CHANGE_PWD_PROCESSING:
        return {
          ...state,
          changePwdError: [],
          statusChangePwd: ChangePwdStatus.CHANGE_PWD_PROCESSING
        }

      case ActionTypes.CHANGE_PWD_SUCCESS:
        return {
          ...state,
          changePwdError: [],
          statusChangePwd: ChangePwdStatus.CHANGE_PWD_SUCCESS
        }

      case ActionTypes.CHANGE_PWD_FAILED:
        return {
          ...state,
          changePwdError: action.payload.errorMsg,
          statusChangePwd: ChangePwdStatus.CHANGE_PWD_FAILED
        }

      case ActionTypes.DELETE_TOKEN:
        return {
          ...state,
          error: initialState.error,
          payload: initialState.payload,
          token: initialState.token,
          me: undefined,
        }

      case ActionTypes.STATUS_LOGGING_IN:
        return { ...state, status: LoginStatus.LOGGING_IN }

      case ActionTypes.STATUS_LOGGING_OUT:
        return { ...state, status: LoginStatus.LOGGING_OUT }

      case ActionTypes.STATUS_LOGGED_IN:
        return { ...state, status: LoginStatus.LOGGED_IN }

      case ActionTypes.STATUS_LOGGED_OUT:
        return { ...state, status: LoginStatus.LOGGED_OUT }

      case ActionTypes.ENTER_LOCKDOWN_MODE: {
        const { client } = action.payload
        saveLockdownClient(client)
        return {
          ...state,
          lockdownClient: client
        }
      }

      case ActionTypes.LOGOUT_SUCCESS: {
        deleteLockdownClient()
        return {
          ...state,
          payload: '',
          lockdownClient: undefined,
          me: undefined,
        }
      }

      default:
        return state
    }
  }

function saveLockdownClient(client: Client) {
  localStorage.setItem('lockdownClient', JSON.stringify(client))
}

export function deleteLockdownClient() {
  localStorage.removeItem('lockdownClient')
}

function getLockdownClient(): Client | undefined {
  const clientString = localStorage.getItem('lockdownClient') || undefined
  return clientString ? JSON.parse(clientString) : undefined
}

export default reducer
