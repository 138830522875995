import { action } from "typesafe-actions"

import { ActionTypes } from './action-types'
import { Account, CardDisplayMode } from './types'
import { HedgeCard } from "../types"

export function clearCards() {
  return action(ActionTypes.CLEAR_CARDS)
}

export function loadAccountRequest(accountId: number) {
  return action(ActionTypes.LOAD_ACCOUNT_REQUEST, { accountId })
}

export function loadAccountSuccess(account: Account) {
  return action(ActionTypes.LOAD_ACCOUNT_SUCCESS, account)
}

export function loadAccountFailure() {
  return action(ActionTypes.LOAD_ACCOUNT_FAILURE)
}

export function calculateCardsRequest(account: Account) {
  return action(ActionTypes.CALCULATE_CARDS_REQUEST, { account })
}

export function calculateCardsSuccess(cards: HedgeCard[]) {
  return action(ActionTypes.CALCULATE_CARDS_SUCCESS, cards)
}

export function calculateCardsFailure() {
  return action(ActionTypes.CALCULATE_CARDS_FAILURE)
}

export function resetQuickscan() {
  return action(ActionTypes.RESET_QUICKSCAN)
}

export function updateAccountRequest(account: Account, callback?: any) {
  return action(ActionTypes.UPDATE_ACCOUNT_REQUEST, { account, callback })
}

export function updateAccountSuccess(account: Account) {
  return action(ActionTypes.UPDATE_ACCOUNT_SUCCESS, account)
}

export function updateAccountFailure() {
  return action(ActionTypes.UPDATE_ACCOUNT_FAILURE)
}

export function storeAccount(account: Account) {
  return action(ActionTypes.STORE_ACCOUNT, account)
}

export function setCardDisplayMode(mode: CardDisplayMode) {
  return action(ActionTypes.SET_CARD_DISPLAY_MODE, { mode })
}

export function setBusyCount(count: number) {
  return action(ActionTypes.SET_BUSY_COUNT, count)
}

export function setFinish(show: boolean) {
  return action(ActionTypes.SET_FINISH, show)
}
